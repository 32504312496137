import React, { useEffect, useState } from "react";
import HeaderNFooter from "../Components/HeaderNFooter";
import "../Styles/ThankyouPanel.css";
import ActionButton from "../Components/ActionButton";
import {
    firebaseAnalytics,
    GetCheer,
    WriteDistanceData,
} from "../Utils/firebase";
import { logEvent } from "firebase/analytics";
import { getDatabase, ref, get, child, off } from "firebase/database";

export default function ThankyouPanel({ nextPage }) {
    const [userCheer, setUserCheer] = useState(0);
    const [contributedDist, setContributedDist] = useState(0);
    const [globalProgress, setGlobalProgress] = useState(0);
    const [globalDistanceCovered, setGlobalDistanceCovered] = useState(0);

    function readDistanceData() {
        const dbRef = ref(getDatabase());
        const totalDistance = 7329;
        get(child(dbRef, "distance/distanceTravelled"))
            .then((snapshot) => {
                // setGlobalDistanceCovered(snapshot.val());
                var dist = snapshot.val();
                if (snapshot.val() > 7329) {
                    dist = totalDistance;
                }
                // const mToKm = (dist + Math.floor(userCheer * 0.5)) / 1000;
                var convertedDist = Math.floor(snapshot.val());
                if (convertedDist > 7329) {
                    convertedDist = 7329;
                }
                setGlobalDistanceCovered(snapshot.val());
                const globalPercent = (snapshot.val() / totalDistance) * 100;

                // console.log(globalPercent);
                const offset = Math.floor(globalPercent / 10);
                console.log(offset + " offset")
                if (globalPercent > 100) {
                    setGlobalProgress(100 + offset +10 );
                } else {
                    setGlobalProgress(globalPercent + offset + 5);
                }
            })
            .catch((err) => {
                console.log(err);
                setGlobalProgress(0);
            });
    }

    const handleShare = async () => {
        logEvent(firebaseAnalytics, "Clicked on share activity");
        if (navigator.share) {
            try {
                await navigator.share({
                    title: "Aditya Birla Capital - Cheer To Paris",
                    text: `My cheer was ${userCheer}dB, and it just covered a distance of 1 Kilometer. 
How loud can you cheer for India?  
Participate and join me as we take our cheers to our players at the Paris Olympics.  
India Ab #HadhKarDe`,
                    url: document.location.href,
                });
                logEvent(firebaseAnalytics, "Activity shared successfully");
                console.log("Content shared successfully");
            } catch (error) {
                console.error("Error sharing content:", error);
            }
        } else {
            logEvent(
                firebaseAnalytics,
                "Browser doesnot support sharing activity"
            );
            console.log("Web Share API is not supported in this browser.");
            alert("Sharing is not supported by your browser.");
        }
    };

    const ProceedToForm = () =>{
        logEvent(firebaseAnalytics, "Proceeded to form page");
        nextPage();
    }

    useEffect(() => {
        const cheer = Math.floor(GetCheer());
        setUserCheer(cheer);
        const dist = Math.floor(cheer * 0.5);
        setContributedDist(dist);
        readDistanceData();
    }, [userCheer, contributedDist]);

    return (
        <>
            <div className="panel thankyouScreen">
                <HeaderNFooter />
                <div className="wowTxt centerX"></div>
                <div className="thankyouTxt centerX"><b>Submit, share, and <br/>get ready to win!</b></div>

                <p className="cheerInfo centerX">
                    Your cheer was <b>{userCheer} decibel</b> loud! <br /> 
                    <span style={{color: "white", fontSize: "2svh"}}>It took us 1 km closer to Paris.</span>
                </p>
                {/* <p className="thankyouDesc centerX">
                    Keep an ear out because your cheer could be the loudest,
                    earning you a ticket to Paris!
                </p> */}
                {/*  */}
                <div className="endGlobalProgressDisplay centerX">
                    <p className="progressText">
                        <span className="dist">{globalDistanceCovered}</span> km <br/>{" "}
                        <span className="distEnd">covered till now</span>{" "}
                        {/* <span className="distEnd">till now.</span> */}
                    </p>
                </div>

                {/* <div className="thankyouMap"></div> */}
                <div className="endGlobalTrackerWrapper">
                    <div className="endGlobalStart">
                    <div className="dot dotStart"></div>
                    </div>
                    <div className="endGlobalMeter">
                        <div className="endGlobalMask">
                            <div className="endGlobalMeterBG"></div>
                            <div
                                style={{ width: globalProgress + "%" }}
                                className="endGlobalProgress"
                            ></div>
                        </div>
                    </div>
                    <div className="endGlobalPointer"></div>
                    <div className="endGlobalEnd">
                    <div className="dot dotEnd"></div>
                    </div>
                </div>
                <div className="endOptions">
                    {/* <ActionButton
                        onButtonClick={handleShare}
                        btnText="Share"
                        btnID="ShareBtn"
                        btnClass="shareBtnThankyou"
                    /> */}
                    <ActionButton
                        onButtonClick={ProceedToForm}
                        btnText="Next"
                        btnID="CheerAgainBtn"
                        btnClass="cheerAgainBtn"
                        
                    />
                </div>
            </div>
        </>
    );
}
