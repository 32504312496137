import { useState, useEffect } from "react";

const VideoPreviewAndroid = ({ previewChunks }) => {
    const [onScreenLogger, setOnScreenLogger] = useState("");
    // const [url, setUrl] = useState("");
    const [_file, setFile] = useState("");

    useEffect(() => {
        console.log("OK");
        const vidElm = document.getElementById("myVideo");

        
    }, []);

    if (previewChunks.length === 0 || previewChunks == null) {
        console.log("no frames found");
        setOnScreenLogger("No frames found");
        return null; // Don't render anything if no recorded chunks
    }

    const blob = new Blob(previewChunks, { type: "video/mp4" });
    const url = URL.createObjectURL(blob);

    return (
        <div className="video-preview centerX">            
            <video
                id="myVideo"
                className="preview centerX"
                // controls       
                autoPlay         
                loop
            >
                <source src={url} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default VideoPreviewAndroid;
