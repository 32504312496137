import React, { useEffect, useState, useCallback, useRef } from "react";
import { WriteHighestCheer } from "../Utils/firebase";
import SoundMeterDisplay from "./SoundMeterDisplay";

const SoundLevelMeter = ({ onRecordingSubmitted, isRunning }) => {
    const [decibels, setDecibels] = useState(null);
    const [displayDecibels, setDisplayDecibels] = useState(null);
    const [isMicSetup, setIsMicSetup] = useState(false);
    const [onScreenLogger, setOnScreenLogger] = useState("");
    const micIntervalRef = useRef(null);
    var highestDecibels = 0;
    var audioContext;
    var analyser;
    var source;

    useEffect(() => {
        // console.log(isRunning ? "audio Started" : "audio stopped");
        // if (isMicSetup) {
        //     setupMic();
        //     setIsMicSetup(true);
        // }

        if (isRunning) {
            startMic();
        } else {
            WriteHighestCheer(displayDecibels);
            highestDecibels = 0;
            // setDecibels(null);
            // setDisplayDecibels(0);
            setOnScreenLogger("clearing interval");
            try {
                audioContext.suspend();
            } catch (error) {
                // console.log("no audio context found");
            }
            if (micIntervalRef.current) {
                clearInterval(micIntervalRef.current);
            }
        }
    }, [isRunning, onRecordingSubmitted]);

    const setupMic = () => {
        audioContext = new AudioContext();
        analyser = audioContext.createAnalyser();
        analyser.fftSize = 2048;
        analyser.smoothingTimeConstant = 0.85;

        analyser.minDecibels = -70;
        analyser.maxDecibels = -10;
    };

    const startMic = () => {
        // console.log("setting up mic");
        setIsMicSetup(true);
        // if (!isMicSetup) {
        //     audioContext = new AudioContext();
        //     analyser = audioContext.createAnalyser();
        //     analyser.fftSize = 2048;
        //     analyser.smoothingTimeConstant = 0.85;

        //     analyser.minDecibels = -70;
        //     analyser.maxDecibels = -10;
        // }
        audioContext = new AudioContext();
        analyser = audioContext.createAnalyser();
        analyser.fftSize = 2048;
        analyser.smoothingTimeConstant = 0.85;

        analyser.minDecibels = -70;
        analyser.maxDecibels = -10;
        highestDecibels = 0;

        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                audioContext.resume();
                source = audioContext.createMediaStreamSource(stream);
                source.connect(analyser);

                const dataArray = new Float32Array(analyser.fftSize);
                const updateDecibels = () => {
                    analyser.getFloatTimeDomainData(dataArray);
                    const rmsValue = Math.sqrt(
                        dataArray.reduce((acc, val) => acc + val * val, 0) /
                            dataArray.length
                    );
                    const decibelValue = 20 * Math.log10(rmsValue);
                    setDecibels(decibelValue);
                    if (35 + decibelValue > highestDecibels) {
                        highestDecibels = 35 + decibelValue;
                    }

                    var convertedValue = (highestDecibels / 35) * 100;
                    if (convertedValue > 100) {
                        convertedValue = 100;
                    }
                    window.highestDecibels = convertedValue;
                    setDisplayDecibels(convertedValue);
                };
                micIntervalRef.current = setInterval(updateDecibels, 100);
            })
            .catch((error) =>
                console.error("Error accessing microphone:", error)
            );
    };

    return (
        <>
            <div id="soundMeter" className="soundMeter">
                {decibels !== null ? (
                    <SoundMeterDisplay
                        showDecibels={true}
                        meterID="instructionMeterRecord"
                        meterHeight={Math.floor(displayDecibels)}
                    />
                ) : (
                    // <p>Microphone access required</p>
                    <></>
                )}
            </div>
        </>
    );
};

export default SoundLevelMeter;
