import React, { useEffect, useState } from "react";
import HeaderNFooter from "../Components/HeaderNFooter";
import "../Styles/EndPanel.css";
import ActionButton from "../Components/ActionButton";
import {
    firebaseAnalytics,
    GetCheer,
    WriteDistanceData,
    GetName,
} from "../Utils/firebase";
import { logEvent } from "firebase/analytics";
import { getDatabase, ref, get, child, off } from "firebase/database";

export default function EndPanel({knowMore}) {
    const [userCheer, setUserCheer] = useState(0);
    const [contributedDist, setContributedDist] = useState(0);
    const [globalProgress, setGlobalProgress] = useState(0);
    const [globalDistanceCovered, setGlobalDistanceCovered] = useState(0);
    const [userName, setUserName] = useState("");
    const [eventLogged, setEventLogged] = useState(false);

    function readDistanceData() {
        const dbRef = ref(getDatabase());
        const totalDistance = 7329;
        get(child(dbRef, "distance/distanceTravelled"))
            .then((snapshot) => {
                // setGlobalDistanceCovered(snapshot.val());
                var dist = snapshot.val();
                if (snapshot.val() > 7329) {
                    dist = totalDistance;
                }
                // const mToKm = (dist + Math.floor(userCheer * 0.5)) / 1000;
                var convertedDist = Math.floor(snapshot.val() + 1);
                if (convertedDist > 7329) {
                    convertedDist = 7329;
                }

                setGlobalDistanceCovered(snapshot.val());
                const globalPercent = (snapshot.val() / totalDistance) * 100;
                const offset = Math.floor(globalPercent / 10);
                if (globalPercent > 100) {
                    setGlobalProgress(100 + offset + 10 );
                } else {
                    setGlobalProgress(globalPercent + offset +4);
                }
            })
            .catch((err) => {
                console.log(err);
                setGlobalProgress(0);
            });
    }

    const handleShare = async () => {
        logEvent(firebaseAnalytics, "Clicked on share activity");
        if (navigator.share) {
            try {
                await navigator.share({
                    title: "Aditya Birla Capital - Cheer To Paris",
                    text: `My cheer was ${userCheer} decibels loud, and it took us 1 kilometer closer to Paris. How loud can you cheer for Team India? Participate now and stand a chance to win a trip to Paris and other exciting prizes*. 
India, Ab #HadhKarDe 
                    
                    
`,
                    url: document.location.href,
                });
                logEvent(firebaseAnalytics, "Activity shared successfully");
                console.log("Content shared successfully");
            } catch (error) {
                console.error("Error sharing content:", error);
            }
        } else {
            logEvent(
                firebaseAnalytics,
                "Browser doesnot support sharing activity"
            );
            console.log("Web Share API is not supported in this browser.");
            alert("Sharing is not supported by your browser.");
        }
    };

    const PushEventToCleverTap = () => {
        window.clevertap.event.push("Registration_Successful"); // Can use any event name required
    }

    const ReloadSite = () => {
        if (eventLogged) {
            PushEventToCleverTap();
        }
        logEvent(firebaseAnalytics, "Restarted activity");
        window.location.reload(true);
    };

    useEffect(() => {
        const cheer = Math.floor(GetCheer());
        setUserCheer(cheer);
        const dist = Math.floor(cheer * 0.5);
        setContributedDist(dist);
        readDistanceData();
        const _name = GetName();
        setUserName(_name);
    }, [userCheer, contributedDist, userName]);

    useEffect(() => {
        if (!window.eventLogged && !eventLogged) {
            setTimeout(() => {

                console.log("Pushed to clever tap");
                PushEventToCleverTap();
            }, 8000)
            setEventLogged(true);
        }
    }, [eventLogged])

    return (
        <>
            <div className="panel endScreen">
                <HeaderNFooter />
                {/* <div className="wowTxt centerX"></div> */}
                <div className="endMessage centerX">
                    <span style={{ fontSize: "3svh" }}>Thank you, </span>
                    <br /> <b>{userName}</b> <br />
                    <span style={{ fontSize: "3svh" }}>
                        {" "}
                        for cheering for Team India!
                    </span>
                </div>

                <div className="endGlobalProgressDisplay forEndPanel centerX">
                    <p className="progressText">
                        <span className="dist">{globalDistanceCovered}</span> km{" "}<br />
                        <span className="distEnd">covered till now</span>{" "}
                        {/* <span className="distEnd">till now.</span> */}
                    </p>
                </div>

                {/* <div className="thankyouMap"></div> */}
                <div className="endGlobalTrackerWrapper endPanelWrapper">
                    <div className="endGlobalStart endPanelGlobalStart">
                        <div className="dot dotStart"></div>
                    </div>
                    <div className="endGlobalMeter endPanelGlobalMeter">
                        <div className="endGlobalMask">
                            <div className="endGlobalMeterBG"></div>
                            <div
                                style={{ width: globalProgress + "%" }}
                                className="endGlobalProgress"
                            ></div>
                        </div>
                    </div>
                    <div className="endGlobalPointer"></div>
                    <div className="endGlobalEnd endPanelGlobalEnd">
                        <div className="dot dotEnd"></div>
                    </div>
                </div>

                <p className="endDesc centerX">
                    {/* Keep an ear out because your cheer could be the loudest,
                    earning you a ticket to Paris! */}
                </p>
                <div className="endOptions">
                    <ActionButton
                        onButtonClick={handleShare}
                        btnText="Share"
                        btnID="ShareBtn"
                        btnClass="shareBtnEnd"
                    />
                    <ActionButton
                        onButtonClick={ReloadSite}
                        btnText="CHEER AGAIN"
                        btnID="CheerAgainBtn"
                        btnClass="cheerAgainBtn cheerAgainBtnEnd"
                    />
                </div>
            </div>
        </>
    );
}
