// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, set, get, child } from "firebase/database";
import { doc, getFirestore, serverTimestamp, setDoc } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_KEY,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

var sessionUserID = -1;
var highestCheer;
var videoURL;
var name = "";
var tempUID = -1;

export function GetCheer() {
    return highestCheer;
}

export function GetName() {
    // if (name === "") {
    //     name = "Ranjan Shrestha";
    // }
    return name;
}

export function setUsername(_name) {
    name = _name;
}


export async function WriteUserData(_name, _email, _phone, _dept) {
    const db = getFirestore(firebaseApp);

    if (_dept == null || _dept == "") {
        _dept = "Not An Employee";
    }
    name = _name;

    // if (highestCheer === undefined) {
    //     highestCheer = 0;
    // }
    // if (videoURL === undefined) {
    //     videoURL = "Test URL";
    // }
    // if (sessionUserID === -1) {
    //     sessionUserID = Date.now()
    // }    
    var identity = Date.now() + "_" + Math.floor(Math.random() * 100000)
    await setDoc(doc(db, "Users", identity), {
        name: _name,
        email: _email,
        phone: _phone,
        department: _dept,
        loudestCheer: highestCheer,
        videoURL: videoURL,
        timestamp: serverTimestamp(),
        tncAgreed: "Yes"
    });
    WriteDistanceData(Math.floor(highestCheer));
    // console.log("wrote user data");
    return identity;
}

export function GetSessionID() {
    return sessionUserID;
}

export function GetTempUserID() {
    return tempUID;
}

export async function UpdateUserDetail(_videoURL) {
    videoURL = _videoURL;
    // console.log("uploaded video");
}

export function setSessionID(sessionID) {
    sessionUserID = sessionID;
}

export function WriteVerificationID() {
    if (tempUID === -1) {
        tempUID = Date.now() + Math.random() * 10000;
    }
    const dbRef = ref(getDatabase());
    get(child(dbRef, "auth/"))
        .then((snapshot) => {
            var updatedDistance = snapshot.val() + 1;
            const db = getDatabase();
            set(ref(db, "distance/"), {
                distanceTravelled: updatedDistance,
            });
        })
        .catch((err) => {
            // console.log(err);
        });
}

export function WriteDistanceData(distance) {
    const dbRef = ref(getDatabase());
    get(child(dbRef, "distance/distanceTravelled"))
        .then((snapshot) => {
            var updatedDistance = snapshot.val() + 1;
            const db = getDatabase();
            set(ref(db, "distance/"), {
                distanceTravelled: updatedDistance,
            });
        })
        .catch((err) => {
            // console.log(err);
        });
}

export function ReadDistanceData() {
    const dbRef = ref(getDatabase());

    get(child(dbRef, "distance/distanceTravelled"))
        .then((snapshot) => {
            return snapshot.val();
        })
        .catch((err) => {
            // console.log(err);
            return 0;
        });
}

export async function WriteHighestCheer(decibel) {
    highestCheer = decibel;
    // console.log("uploaded cheer");
    return "Done";
}

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseStorage = getStorage(firebaseApp);
export const firebaseAnalytics = getAnalytics(firebaseApp);
