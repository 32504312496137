import React from "react";
import "../Styles/HeaderNFooter.css"

export default function HeaderNFooter() {

    const RedirectToHome = ()=>{
        console.log("redirecting")
        window.location = window.location.origin//+"?microsite=true";
    }

    return <>
        <div onClick={RedirectToHome} className="logo centerX"></div>
        {/* <div className="legal centerX">
            <p>Privacy Policy</p>
            <p>*T&C Apply</p>
        </div> */}
    </>;
}
