import { useEffect, useState } from "react";

const VideoPreview = ({ previewChunks }) => {
    const [onScreenLogger, setOnScreenLogger] = useState("");
    const [url, setUrl] = useState("");

    useEffect(() => {
        if (previewChunks.length === 0 || previewChunks == null) {
            console.log("no frames found");
            setOnScreenLogger("No frames found");
            return null; // Don't render anything if no recorded chunks
        }

        const blob = new Blob(previewChunks, { type: "video/mp4" });
        const url = URL.createObjectURL(blob);

        setOnScreenLogger("In mp4");
        setUrl(url);
    }, []);

    return (
        <div className="video-preview centerX">
            {/* <div className="screenLogger">{onScreenLogger}</div> */}
            <video
                id="videoPreview"
                className="preview"
                playsInline                
                preload="true"
                autoPlay
                loop
            >
                <source src={url} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default VideoPreview;
