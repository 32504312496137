import React, { useEffect, useRef, useState } from "react";
import "../Styles/Carousel.css";
import { useSwipeable } from "react-swipeable";
import PopUpScrollView from "./PopUpScrollView";

export default function Carousel() {
    const [canScrollCarousel, setCanScrollCarousel] = useState(true);
    const [showReadMore, setShowReadMore] = useState(false);
    const [carouselInvervalStarted, setCarouselIntervalStarted] =
        useState(false);
    const carouselRef = useRef(null);
    const [carouselCount, setCarouselCount] = useState(1);
    const [isDesktop, setIsDesktop] = useState(false);

    const handleSwipeLeft = () => {
        if (!canScrollCarousel) return;
        if (carouselCount >= 9) {
            setCarouselCount(1);
        } else setCarouselCount(carouselCount + 1);
    };

    const handleSwipeRight = () => {
        if (carouselCount <= 1) {
            setCarouselCount(9);
        } else setCarouselCount(carouselCount - 1);
    };

    useEffect(() => {
        try {
            document.getElementById("carousel-" + carouselCount).checked = true;
        } catch (error) {
            console.log(carouselCount);
        }
    }, [carouselCount]);

    // useEffect(() => {
    //     if (!canScrollCarousel) return;
    //     const carousalInterval = setInterval(handleSwipeLeft, 10000);
    //     return () => clearInterval(carousalInterval);
    // }, [carouselCount, canScrollCarousel]);

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            if (showReadMore) return;
            if (eventData.dir === "Left") {
                handleSwipeLeft();
            } else if (eventData.dir === "Right") {
                handleSwipeRight();
            }
        },
    });

    useEffect(() => {
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);

        let regex = /iphone|Macintosh/i;
        let isIPhone = regex.test(details);

        if (isIPhone) {
            window.isIPhone = true;
        } else {
            window.isIPhone = false;
        }

        if (isMobileDevice) {
            console.log("is not");
            setIsDesktop(false);
        } else {
            console.log("is Desktop");
            setIsDesktop(true);
        }
    }, [isDesktop]);

    const handleReadMore = () => {
        setCanScrollCarousel(false);
        setShowReadMore(true);
    };

    const handleCloseReadMore = () => {
        setCanScrollCarousel(true);
        setShowReadMore(false);
    };

    return (
        <>
            <div ref={carouselRef} {...handlers} className="carouselContainer">
                {/* <div className="carouselHeader centerX">Know Your Player</div> */}
                <div className="carousel">
                    <input
                        type="radio"
                        id="carousel-1"
                        value={1}
                        name="carousel[]"
                        defaultChecked
                        disabled={!isDesktop}
                    />
                    <input
                        type="radio"
                        value={2}
                        id="carousel-2"
                        name="carousel[]"
                        disabled={!isDesktop}
                    />
                    <input
                        type="radio"
                        value={3}
                        id="carousel-3"
                        name="carousel[]"
                        disabled={!isDesktop}
                    />
                    <input
                        type="radio"
                        value={4}
                        id="carousel-4"
                        name="carousel[]"
                        disabled={!isDesktop}
                    />
                    {/* <input
                        type="radio"
                        value={5}
                        id="carousel-5"
                        name="carousel[]"
                        disabled={!isDesktop}
                    />
                    <input
                        type="radio"
                        value={6}
                        id="carousel-6"
                        name="carousel[]"
                        disabled={!isDesktop}
                    />
                    <input
                        type="radio"
                        value={7}
                        id="carousel-7"
                        name="carousel[]"
                        disabled={!isDesktop}
                    />
                    <input
                        type="radio"
                        value={8}
                        id="carousel-8"
                        name="carousel[]"
                        disabled={!isDesktop}
                    />
                    <input
                        type="radio"
                        value={9}
                        id="carousel-9"
                        name="carousel[]"
                        disabled={!isDesktop}
                    /> */}
                    <ul className="carousel__items">
                        <li className="carousel__item">
                            
                            {!isDesktop ? (
                                <div className="item">
                                <div className="carouselPlayer manu"></div>
                                <div className="carouselPlayerName centerX">
                                    Manu
                                    <br />
                                    Bhaker
                                </div>
                                <div className="playerInfo2 centerX">
                                Shooting Prodigy
                                </div>
                                <div className="playerDesc centerX">
                                Manu Bhaker became the first Indian woman to win a Shooting medal at Olympics and the first athlete from independent India to win two medals in a single Olympics campaign.
                                Also, Manu Bhaker and Sarabjot Singh became the first Indian shooting pair to win an Olympic medal at Paris 2024.
                                </div>
                                </div>
                            ) : (
                                <div className="container">
                                    <div className="content">
                                       <div className="image-container">
                                        <img src="/Images/Players/Manu.png" alt="Manu Bhaker"/>
                                       </div>
                                       <div className="text-container">
                                           <div className="knowPlayer">#HadhKarDe</div>
                                           <div className="FirstRoar">Firsts that roared at the Olympics</div>
                                           <div className="title1">Manu Bhaker</div>
                                           <div className="btn">
                                             <button>Shooting</button>
                                           </div>
                                           <div className="aceDragFlicker">Shooting Prodigy</div>
                                           <div className="description">
                                               <p>Manu Bhaker became the first Indian woman to win a Shooting medal at Olympics and the first athlete from independent India to win two medals in a single Olympics campaign.</p>
                                               <p>Also, Manu Bhaker and Sarabjot Singh became the first Indian shooting pair to win an Olympic medal at Paris 2024.</p>
                                           </div>
                                       </div>
                                       </div>
                                       <div className="fog">
                                           
                                       </div>
                                </div>
                            )}
                        </li>
                        <li className="carousel__item">
                            
                                {!isDesktop ? (
                                    <div className="item">
                                    <div className="carouselPlayer manika"></div>
                                    <div className="carouselPlayerName centerX">
                                        Manika
                                        <br />
                                        Batra
                                    </div>
                                    <div className="playerInfo1 centerX">
                                    Queen of India's Table Tennis
                                    </div>
                                    <div className="playerDesc1 centerX">
                                    Manika Batra became the first Indian table tennis player to make pre-quarterfinals.
                                    </div>
                                    </div>
                                ) : (
                                    <div className="container">
                                        <div className="content">
                                           <div className="image-container2">
                                            <img src="/Images/Players/Manika.png" alt="Manika Batra"/>
                                           </div>
                                           <div className="text-container">
                                               <div className="knowPlayer">#HadhKarDe</div>
                                               <div className="FirstRoar">Firsts that roared at the Olympics</div>
                                               <div className="title1">Manika Batra</div>
                                               <div className="btn">
                                                 <button>Table Tennis</button>
                                               </div>
                                               <div className="aceDragFlicker">Queen of India's Table Tennis</div>
                                               <div className="description">
                                                   <p>Manika Batra became the first Indian table tennis player to make pre-quarterfinals, </p>
                                               </div>
                                           </div>
                                           </div>
                                           <div className="fog">
                                               
                                           </div>
                                    </div>
                                )}
                        </li>
                        <li className="carousel__item">
                           
                                {!isDesktop ? (
                                     <div className="item">
                                     <div className="carouselPlayer neeraj"></div>
                                     <div className="carouselPlayerName centerX">
                                         Neeraj
                                         <br />
                                         Chopra
                                     </div>
                                     <div className="playerInfo1 centerX">
                                     The Golden Boy of India
                                     </div>
                                    <div className="playerDesc1 centerX">
                                    Neeraj Chopra became the first Indian track-and-field athlete to win medals at consecutive Olympics
                                    </div>
                                    </div>
                                ) : (
                                    <div className="container">
                                        <div className="content">
                                           <div className="image-container1">
                                            <img src="/Images/Players/Neeraj.png" alt="Neeraj Chopra"/>
                                           </div>
                                           <div className="text-container">
                                               <div className="knowPlayer">#HadhKarDe</div>
                                           <div className="FirstRoar">Firsts that roared at the Olympics</div>
                                               <div className="title1">Neeraj Chopra</div>
                                               <div className="btn">
                                                 <button>Javelin Throw</button>
                                               </div>
                                               <div className="aceDragFlicker">The Golden Boy of India</div>
                                               <div className="description">
                                                   <p>Neeraj Chopra became the first Indian track-and-field athlete to win medals at consecutive Olympics</p>
                                               </div>
                                           </div>
                                           </div>
                                           <div className="fog">
                                               
                                           </div>
                                    </div>
                                )}
                        </li>
                        <li className="carousel__item">
                            
                                {!isDesktop ? (
                                    <div className="item">
                                    <div className="carouselPlayer sindhu"></div>
                                    <div className="carouselPlayerName centerX">
                                        Lakshya
                                        <br />
                                        Sen
                                    </div>
                                    <div className="playerInfo1 centerX">
                                        Badminton icon
                                    </div>
                                    <div className="playerDesc1 centerX">
                                    Lakshya Sen became the first Indian shuttler to reach the semi-finals in a men's Men's Badminton event at the Olympics.
                                    </div>
                                    </div>
                                ) : (
                                    <div className="container">
                                        <div className="content">
                                           <div className="image-container">
                                            <img src="/Images/Players/Lakshya.png" alt="Lakshya Sen"/>
                                           </div>
                                           <div className="text-container">
                                               <div className="knowPlayer">#HadhKarDe</div>
                                           <div className="FirstRoar">Firsts that roared at the Olympics</div>
                                               <div className="title1">Lakshya Sen</div>
                                               <div className="btn">
                                                 <button>Badminton</button>
                                               </div>
                                               <div className="aceDragFlicker">Badminton icon</div>
                                               <div className="description">
                                                   <p>Lakshya Sen became the first Indian shuttler to reach the semi-finals in a men's Men's Badminton event at the Olympics.</p>
                                               </div>
                                           </div>
                                           </div>
                                           <div className="fog">
                                               
                                           </div>
                                    </div>
                                )}
                        </li>
                    </ul>

                    <div className="carousel__nav centerX">
                        <label htmlFor="carousel-1"></label>
                        <label htmlFor="carousel-2"></label>
                        <label htmlFor="carousel-3"></label>
                        <label htmlFor="carousel-4"></label>
                        {/* <label htmlFor="carousel-5"></label>
                        <label htmlFor="carousel-6"></label>
                        <label htmlFor="carousel-7"></label>
                        <label htmlFor="carousel-8"></label>
                        <label htmlFor="carousel-9"></label> */}
                    </div>
                </div>

                <>
                    {showReadMore && carouselCount === 1 && (
                        <PopUpScrollView
                            popupId="harmanpreet"
                            header="Harmanpreet Singh: The Ace Drag Flicker"
                            textContent={
                                <>
                                    <p>
                                        <b>Captain of Indian Men’s Hockey Team</b>
                                        <br />
                                        <br />
                                        Gold medal at 2022 Asian Games<br />
                                        Silver medal at 2022 Commonwealth Games<br />
                                        Bronze medal at 2020 Tokyo Olympics<br />
                                        <br />
                                        At just 28 years old, Harmanpreet Singh
                                        has emerged as the linchpin of the
                                        Indian men’s hockey team. His powerful
                                        drag flicks, clocking speeds exceeding
                                        100 km/h, strike fear into opposing
                                        goalkeepers. His journey began in
                                        Jalandhar, where he honed his skills at
                                        the Sunder Nagar Academy. With his prime
                                        years still ahead, this drag-flick
                                        sensation is poised to propel Indian
                                        hockey to unprecedented heights,
                                        embodying the Ab #HadhKarDe spirit with
                                        unmatched passion and prowess.
                                    </p>
                                </>
                            }
                            onClose={handleCloseReadMore}
                        />
                    )}
                    {showReadMore && carouselCount === 2 && (
                        <PopUpScrollView
                            popupId="lovlina"
                            header="Lovlina Borgohain: The Punching Powerhouse"
                            textContent={
                                <>
                                    <p>
                                        <b>Boxer, 75 Kg category</b>
                                        <br />
                                        <br />
                                        Gold medal at 2023 IBA Women’s World Championship<br />
                                        Silver medal at 2022 Asian Games<br />
                                        Bronze medal at 2020 Tokyo Olympics<br />
                                        <br />
                                        At just 26 years old, her journey from
                                        the Golaghat district to the Olympic
                                        podium embodies relentless grit and the
                                        unwavering drive to conquer all
                                        obstacles. Lovlina burst onto the world
                                        stage by securing the welterweight
                                        bronze at the Tokyo 2020
                                        Olympics—India’s sole boxing medal. Her
                                        powerful punches and unwavering resolve
                                        have established her as a formidable
                                        force in the ring. Lovlina Borgohain’s
                                        story inspires generations, proving that
                                        resilience knows no bounds.
                                    </p>
                                </>
                            }
                            onClose={handleCloseReadMore}
                        />
                    )}
                    {showReadMore && carouselCount === 3 && (
                        <PopUpScrollView
                            popupId="kishore"
                            header="Kishore Jena: The Javelin Dynamo"
                            textContent={
                                <>
                                    <p>
                                        <b>Javelin Throw</b>
                                        <br />
                                        <br />
                                        Personal best: 87.54m (2023)<br />
                                        Silver medal at Asian Games 2022<br /><br />

                                        Hailing from a small village in Odisha’s
                                        Jajpur district, Kishore’s raw talent
                                        and unwavering dedication have propelled
                                        him to the pinnacle of Indian javelin
                                        throwing. With a personal best of 87.54
                                        meters, he has firmly established
                                        himself as one of India's finest
                                        athletes in the sport. Before pursuing
                                        javelin, he was a volleyball player who
                                        quit the sport due to his short stature.
                                        Today, Kishore's journey is a testament
                                        to grit and perseverance.
                                    </p>
                                </>
                            }
                            onClose={handleCloseReadMore}
                        />
                    )}
                    {showReadMore && carouselCount === 4 && (
                        <PopUpScrollView
                            popupId="manika"
                            header="Manika Batra: Queen of India's Table Tennis"
                            textContent={
                                <>
                                    <p>
                                        <b>Table Tennis </b>
                                        <br />
                                        <br />
                                        ITTF Table Tennis World Ranking No.26<br />
                                        Bronze medal (Women’s Singles) at 2022 Asian Cup<br />
                                        Gold medal (Women’s Team) at 2018 Commonwealth Games<br />
                                        Gold medal (Women’s Singles) at 2018 Commonwealth Games<br /><br />

                                        Hailing from Delhi, Manika Batra’s love
                                        for table tennis ignited at a tender
                                        age. She began playing at just 4 years
                                        old, fuelled by passion and
                                        determination. Known for her distinct
                                        playing style and unique use of long
                                        pimple rubber, she earned the Major
                                        Dhyan Chand Khel Ratna award in 2020.
                                        Her career highlights include turning
                                        down modelling and film offers, leading
                                        India to a historic team gold at the
                                        2018 Commonwealth Games and becoming the
                                        first Indian woman to win an individual
                                        gold medal in table tennis at the
                                        Commonwealth Games. Manika's journey
                                        showcases her relentless drive and
                                        perseverance.
                                    </p>
                                </>
                            }
                            onClose={handleCloseReadMore}
                        />
                    )}
                    {showReadMore && carouselCount === 5 && (
                        <PopUpScrollView
                            popupId="manu"
                            header="Manu Bhaker: Shooting Prodigy"
                            textContent={
                                <>
                                    <p>
                                        <b>Shooting </b>
                                        <br />
                                        <br />
                                        Gold medal (25m Pistol Team) at 2023 ISSF World Shooting Championships<br />
                                        Gold medal (10m Air Rifle) at 2018 Youth Olympic Games<br />
                                        Gold medal (10m Air Pistol) at 2018 Commonwealth Games<br /><br />

                                        Manu Bhaker, the 22-year-old shooting
                                        sensation from Gohana, Haryana, made her
                                        mark by clinching gold in the 10m Air
                                        Pistol event at the 2018 Youth Olympic
                                        Games when she was just 16. She was the
                                        first Indian shooter and the first
                                        female athlete from India to win a gold
                                        at the Youth Olympics. Inspired by the
                                        2008 Beijing Olympics, she began her
                                        shooting journey in 2015 and quickly
                                        rose to prominence on the international
                                        stage. Manu exemplifies the unwavering
                                        determination and focus that
                                        characterize Indian sporting excellence,
                                        continually breaking records and raising
                                        the bar in her discipline.
                                    </p>
                                </>
                            }
                            onClose={handleCloseReadMore}
                        />
                    )}
                    {showReadMore && carouselCount === 6 && (
                        <PopUpScrollView
                            popupId="mirabai"
                            header="Mirabai Chanu: Indian weightlifting’s serial winner"
                            textContent={
                                <>
                                    <p>
                                        <b>Weightlifting</b>
                                        <br />
                                        <br />
                                        Gold medal at 2022 Commonwealth Games <br />
                                        Silver medal at 2020 Tokyo Olympics<br />
                                        Gold medal at 2018 Commonwealth Games      <br /><br />

                                        Mirabai Chanu, hailing from Nongpok
                                        Kakching, Imphal, is a remarkable Indian
                                        weightlifter. She secured an Olympic
                                        silver medal, clinched a world
                                        championship gold, and earned three
                                        Commonwealth Games golds. Her silver at
                                        Tokyo 2020 was a historic achievement,
                                        marking only the second Olympic medal
                                        for an Indian weightlifter. The
                                        Government of India recognized her
                                        contributions to the sport by awarding
                                        her the prestigious Padma Shri. In 2018,
                                        she received the Major Dhyan Chand Khel
                                        Ratna, a top sporting honour in India.
                                        Mirabai’s journey, from lifting firewood
                                        as a child to setting a world record at
                                        the 2021 Asian Championships,
                                        exemplifies unwavering determination and
                                        perseverance.
                                    </p>
                                </>
                            }
                            onClose={handleCloseReadMore}
                        />
                    )}
                    {showReadMore && carouselCount === 7 && (
                        <PopUpScrollView
                            popupId="neeraj"
                            header="Neeraj Chopra: The Golden Boy of India"
                            textContent={
                                <>
                                    <p>
                                        <b>Javelin Throw</b>
                                        <br />
                                        <br />
                                        World ranking No. 1<br />
                                        Gold medal at 2023 World Championships<br />
                                        Gold medal at 2022 Diamond League<br />
                                        Gold medal at 2020 Tokyo Olympics<br /><br />

                                        Neeraj Chopra, India's premier javelin
                                        thrower, has garnered gold medals at the
                                        Olympics, World Championships, Asian
                                        Games, Commonwealth Games, Asian
                                        Championships, and a Diamond League
                                        title. At the Tokyo 2020 Olympics, he
                                        made history by winning India's
                                        first-ever Olympic gold in athletics
                                        with a throw of 87.58m.
                                        <br />
                                        <br />
                                        Starting his journey at 13, Neeraj
                                        quickly rose to prominence under the
                                        guidance of coach Jaiveer Choudhary,
                                        breaking the U20 world record in 2016.
                                        <br />
                                        <br />
                                        His relentless pursuit of excellence
                                        continues to inspire, solidifying his
                                        status as one of India's greatest
                                        athletes.
                                    </p>
                                </>
                            }
                            onClose={handleCloseReadMore}
                        />
                    )}
                    {showReadMore && carouselCount === 8 && (
                        <PopUpScrollView
                            popupId="sindhu"
                            header="PV Sindhu: Badminton Trailblazer"
                            textContent={
                                <>
                                    <p>
                                        <b>Badminton</b>
                                        <br />
                                        <br />
                                        BWF Ranking No. 13<br />
                                        Bronze medal (Women’s Singles) at 2020 Tokyo Olympics<br />
                                        Gold medal (Women’s Singles) at 2019 BWF World Championships<br />
                                        Silver medal (Women’s Singles) at 2016 Rio Olympics<br /><br />

                                        PV Sindhu, a 21st-century sporting icon,
                                        has captivated the world’s attention.
                                        Her remarkable achievements have
                                        elevated Indian sportswomen on the
                                        international stage. Born in Hyderabad
                                        to national-level volleyball players,
                                        Sindhu’s athletic genes were evident
                                        from the start. Under the expert
                                        guidance of Pullela Gopichand, she
                                        embarked on her badminton journey at the
                                        tender age of 8.
                                        <br />
                                        <br />
                                        She etched her name in history by
                                        becoming the first Indian woman to
                                        secure both an Olympic silver and a gold
                                        at the BWF World Championships. At Tokyo
                                        2020, Sindhu added a bronze medal,
                                        further solidifying her status as the
                                        first Indian woman with two Olympic
                                        medals.
                                    </p>
                                </>
                            }
                            onClose={handleCloseReadMore}
                        />
                    )}
                    {showReadMore && carouselCount === 9 && (
                        <PopUpScrollView
                            popupId="nikhat"
                            header="Nikhat Zareen: Born to Win"
                            textContent={
                                <>
                                    <p>
                                        <b>Professional Boxer</b>
                                        <br />
                                        <br />
                                        Gold medal at 2023 IBA Women’s World Boxing Championships<br />
                                        Gold medal at 2022 IBA Women’s World Boxing Championships<br />
                                        Gold medal at 2022 Commonwealth Games<br /><br />

                                        Nikhat Zareen, born on June 14, 1996, in
                                        Nizamabad, Telangana, emerged from
                                        modest beginnings to become a celebrated
                                        name in Indian boxing. Initially a
                                        runner, her curiosity about the absence
                                        of girls in boxing led her to the sport.
                                        She trained under her father, and later
                                        at the Sports Authority of India, where
                                        she won the sub-junior national title in
                                        2009 and gold at the Junior and Youth
                                        World Championships in 2011. Despite
                                        challenges, Nikhat won gold at the 2022-
                                        and 2023-Women's World Championships.
                                        She also secured gold at the 2022
                                        Commonwealth Games and a bronze at the
                                        2023 Asian Games, earning her place in
                                        the Paris 2024 Olympics.
                                    </p>
                                </>
                            }
                            onClose={handleCloseReadMore}
                        />
                    )}
                </>
            </div>
        </>
    );
}
