import React, { useCallback, useEffect, useRef, useState } from "react";
import "../Styles/SoundMeterDisplay.css";

export default function SoundMeterDisplay({
    showDecibels,
    meterID,
    meterHeight,
}) {
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        const offsetHeight = (meterHeight/10);        
        document.getElementById("maskedMeterLevel").style.height =
            meterHeight-(offsetHeight-5) + "%";
        setOffset(10 - (meterHeight/10));
        
    }, [meterHeight, offset]);

    return (
        <>
            <div id={meterID} className="soundMeterDisplay">
                <div className="maxIcon"></div>
                <div className="meter">
                    <div className="bgMeter">
                        <div className="maskedMeterBG"></div>
                        <div
                            id="maskedMeterLevel"
                            className="maskedLevel"
                        ></div>
                    </div>
                    {showDecibels && (<div
                        className="dbText"
                        style={{ top: (100-offset) - meterHeight + "%" }}
                    >{meterHeight}dB</div>)}
                </div>
                <div className="minIcon"></div>
                <div className="soundIcon"></div>
            </div>
        </>
    );
}
