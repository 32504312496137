import "./App.css";
import HomePanel from "./Panels/HomePanel";
import InstructionPanel from "./Panels/InstructionPanel";
import RecordingPanel from "./Panels/RecordingPanel";
import FormPanel from "./Panels/FormPanel";

import { useCallback, useEffect, useRef, useState } from "react";
import GlobalDistanceTracker from "./Components/GlobalDistanceTracker";
import ThankyouPanel from "./Panels/ThankyouPanel";
import PlayersProfilePanel from "./Panels/PlayersProfilePanel";

import { firebaseAnalytics } from "./Utils/firebase";
import { logEvent } from "firebase/analytics";
import EndPanel from "./Panels/EndPanel";
import Test from "./Panels/Test";

function App() {
    const [homePanelActive, setHomePanelActive] = useState(true);
    const [instructionPanelActive, setInstructionPanelActive] = useState(false);
    const [formPanelActive, setFormPanelActive] = useState(false);
    const [recordingPanelActive, setRecordingPanelActive] = useState(false);
    const [thankyouPanelActive, setThankyouPanelActive] = useState(false);
    const [playerProfileActive, setPlayerProfileActive] = useState(false);
    const [endPanelActive, setEndPanelActive] = useState(false);
    const [isTrackerVisible, setTrackerVisible] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    const [onScreenLogger, setOnScreenLogger] = useState("");
    const [showKnowMore, setShowKnowMore] = useState(false);
    const [showTest, setShowTest] = useState(false);
    window.eventLogged = false;

    const HidePanels = () => {
        setHomePanelActive(false);
        setInstructionPanelActive(false);
        setFormPanelActive(false);
        setRecordingPanelActive(false);
        setTrackerVisible(false);
        setThankyouPanelActive(false);
        setPlayerProfileActive(false);
        setEndPanelActive(false);
        setShowKnowMore(false);
    };

    const ShowHomePanel = () => {
        HidePanels();
        setHomePanelActive(true);
    };

    const ShowInstructionPanel = () => {
        HidePanels();
        // setTrackerVisible(true);
        setInstructionPanelActive(true);
    };

    const ShowFormPanel = () => {
        HidePanels();
        // setTrackerVisible(true);
        setFormPanelActive(true);
    };

    const ShowRecordingPanel = () => {
        HidePanels();
        setRecordingPanelActive(true);
        setInstructionPanelActive(true);
    };

    const ShowRecordingPanelOnMediaReady = () => {
        HidePanels();
        setRecordingPanelActive(true);
    };

    const ShowThankyouPanel = () => {
        HidePanels();
        setThankyouPanelActive(true);
    };

    const ShowEndPanel = () => {
        HidePanels();
        setEndPanelActive(true);
    };

    const ShowPlayersProfilePanel = () => {
        HidePanels();
        setPlayerProfileActive(true);
    };

    const ShowTest = () => {
        HidePanels();
        setShowTest(true);
    }

    const ShowKnowMorePanel = () =>{
        HidePanels();
        setShowKnowMore(true);
    }

    useEffect(() => {
        
        let details = navigator.userAgent;
        // console.log(details);  
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);

        let regex = /iphone|Macintosh/i;
        let isIPhone = regex.test(details);

        if (isIPhone) {
            window.isIPhone = true;
        } else {
            window.isIPhone = false;
        }

        if (isMobileDevice) {
            setOnScreenLogger("mobile");
            setIsDesktop(false);
        } else {
            setOnScreenLogger("PC");

            setIsDesktop(true);
        }

        // document.getElementById("bgmAudio").play();
        const querryParams = new URLSearchParams(window.location.search);
        // const _userFlow = querryParams.get("microsite");        
        // if (_userFlow == "true") {
        //     logEvent(firebaseAnalytics, "Visited microsite from link");
        //     ShowHomePanel();
        // } else {
        //     logEvent(firebaseAnalytics, "Visited site from link");
        //     ShowInstructionPanel();
        // }

        // if (_userFlow != null) {
        //     return;
        // }

        // const _playerProfile = querryParams.get("showPlayers");

        // if (_playerProfile) {
        //     ShowPlayersProfilePanel();
        // }

        // const _testPage = querryParams.get("showPage");

        // if (_testPage == 4) {
        //     ShowFormPanel();
        // } else if (_testPage == 6) {
        //     ShowEndPanel();
        // }
        // else if (_testPage == 5) {
        //     ShowThankyouPanel();
        // }
        // else if (_testPage == 9) {
        //     ShowTest();
        // }
    }, [isDesktop]);

    return (
        <>
            {/* <audio id="bgmAudio" autoPlay loop>
                <source src="/Audio/BGM.wav" type="audio/wav" />
            </audio> */}
            {/* {isDesktop && <div className="isDesktop"></div>} */}
            {/* <div className="screenLogger">{onScreenLogger}</div> */}
            {/* <div className="BGBlur"></div> */}
            {homePanelActive && <HomePanel nextPage={ShowInstructionPanel} />}

            {instructionPanelActive && (
                <InstructionPanel nextPage={ShowRecordingPanel} />
            )}

            {recordingPanelActive && (
                <RecordingPanel
                    onMediaReady={ShowRecordingPanelOnMediaReady}
                    nextPage={ShowThankyouPanel}
                />
            )}

            {thankyouPanelActive && <ThankyouPanel nextPage={ShowFormPanel} />}

            {formPanelActive && <FormPanel nextPage={ShowEndPanel} />}

            {playerProfileActive && <PlayersProfilePanel />}

            {endPanelActive && <EndPanel knowMore={ShowKnowMorePanel} />}

            {/* {showKnowMore && <Know />} */}

            {isTrackerVisible && <GlobalDistanceTracker />}

            {showTest && <Test />}
        </>
    );
}

export default App;
