import { useEffect, useState } from "react";
import "../Styles/GlobalDistanceTracker.css";
import { getDatabase, ref, get, child } from "firebase/database";

export default function GlobalDistanceTracker() {
    const [globalProgress, setGlobalProgress] = useState(0);

    function readDistanceData() {
        const dbRef = ref(getDatabase());
        const totalDistance = 7329;

        get(child(dbRef, "distance/distanceTravelled"))
            .then((snapshot) => {
                const globalPercent = (snapshot.val() / totalDistance) * 100;
                if (globalPercent > 100) {
                    setGlobalProgress(100);
                }else
                {
                    setGlobalProgress(globalPercent);
                }
            })
            .catch((err) => {
                console.log(err);
                setGlobalProgress(0);
            });
    }

    useEffect(() => {
        readDistanceData();
    }, []);

    return (
        <>
            <div className="trackingWrapper centerX">
                {/* <button onClick={write}> write </button>
            <button onClick={readDistanceData}> read </button> */}
                <div className="trackingMeter centerX"></div>
                <div className="globalProgress centerX">
                    <div
                        id="dynamic"
                        className="progress-bar progress-bar-success progress-bar-striped active"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: globalProgress + "%" }}
                    >
                        <span id="current-progress"></span>
                    </div>
                </div>

                <div className="startFlag"></div>
                <div className="endFlag"></div>
                <p className="trackingUpdate centerX">
                    68% Of People Who Have Experienced The magic Cheered From{" "}
                    <b>India To Paris</b>
                </p>
            </div>
        </>
    );
}
