import React, { useEffect } from "react";

export default function PopUpScrollView({popupId, header, textContent, onClose}) {

    const handleCloseTNC = () =>{
        console.log("ok")
        document.getElementById(popupId+"-TNCContainer").scrollTop = 0;
        document.getElementById(popupId).classList.add("hidden");
        // document.getElementById(popupId).classList.remove("w3-animate-left");
        // document.getElementById(popupId).classList.add("w3-animate-right");
        onClose();
    }

    useEffect(()=>{
        console.log(popupId)
    }, [])

    return (
        <>
            <div id={popupId} className="TermsNConditions w3-animate-left">
                <div onClick={handleCloseTNC} className="crossTNC"></div>
                <div id={popupId+"-TNCContainer"} className="TNCContainer ">
                    <div className="TermsNConditionsText">
                        <span className="TNCTitle">
                            <b>{header}</b>
                        </span>
                        <br />
                        {textContent}
                    </div>
                    {/* <div className="closeTNC centerX" onClick={handleCloseTNC}>
                        <b>Back</b>
                    </div> */}
                </div>
            </div>
        </>
    );
}
