import React, { useState } from "react";
import SoundLevelMeter from "../Components/SoundLevelMeter";
import WebcamRecorder from "../Components/WebcamRecorder";

export default function RecordingPanel({ onMediaReady, nextPage}) {

    const [isRecording, setIsRecording] = useState(false);
    const [isRecordingSubmitted, setIsRecordingSubmitted] = useState(false);

    function OnRecordingStarted()
    {        
        setIsRecording(true);
        console.log("recording started")
    }

    function OnRecordingStopped()
    {
        setIsRecording(false);        
    }    


    function OnRecordingSubmitted()
    {
        setIsRecordingSubmitted(true);
        nextPage();
    }
    

    return (
        <>            
            <div className="panel">
                <div id="recordShoutHeader" className="shoutTag centerX">
                    <p className="shoutTxt centerX">Shout loudest</p>
                    <p className="shoutHighlight centerX">"India Ab Hadh Kar De"</p>
                </div>
                <WebcamRecorder onMediaReady={onMediaReady} onRecordingSubmitted={OnRecordingSubmitted} onRecordingStarted={OnRecordingStarted} onRecordingStopped={OnRecordingStopped} />
                <SoundLevelMeter onRecordingSubmitted={isRecordingSubmitted} isRunning={isRecording}  />
            </div>
        </>
    );
}
