import React from "react";
import "../Styles/PlayersProfilePanel.css";

export default function PlayersProfilePanel() {
    return (
        <>
            <div class="clockWrapper">
                <div class="pie spinner"></div>
                <div class="pie filler"></div>
                <div class="clockMask"></div>
            </div>
            <div className="panel profileScreen centerX">Players Profile</div>
        </>
    );
}
