import HeaderNFooter from "../Components/HeaderNFooter";
import ActionButton from "../Components/ActionButton";
import "../Styles/InstructionPanel.css";
import { firebaseAnalytics } from "../Utils/firebase";
import { logEvent } from "firebase/analytics";
import SoundMeterDisplay from "../Components/SoundMeterDisplay";
import { useState } from "react";

export default function InstructionPanel({ nextPage }) {
    const [soundMeter, setSoundMeter] = useState(0);

    function OnProceed() {
        logEvent(firebaseAnalytics, "Proceeded to Record Video.");
        nextPage();
    }

    const handleShowRules = () => {
        logEvent(firebaseAnalytics, "Viewed Terms and Conditions");
        document.getElementById("TNCPanel").classList.remove("hidden");
    };

    const handleCloseRules = () => {
        logEvent(firebaseAnalytics, "Clicked on TNC understood");
        document.getElementById("InstructionRules").scrollTop = 0;
        document.getElementById("TNCPanel").classList.add("hidden");
    };

    return (
        <>
            <div className="panel instructionScreen">
                <HeaderNFooter />
                <div className="instructionHeader">
                    {/* <div className="leftWave"></div>
                    <div className="question centerX">
                        <p className="questionText"><b>BE LOUD. BE PROUD</b></p>
                        <p className="conversionText"><span className="unit"><b>1</b></span> CHEER VIDEO = <span className="unit"><b>1</b></span> KILOMETER</p>CLOSER TO PARIS
                    </div>
                    <div className="rightWave"></div> */}
                </div>

                {/* <div className="instructionBox centerX">
                    <p className="boxTitle">
                        <b>Be Loud. Be Proud.</b>
                    </p>
                    <p className="boxDesc">
                        Tap and hold the icon to record your loudest cheer.
                        Watch the dB meter to see just how loud you can get! As
                        you record, remember to shout{" "}
                        <b>'India, ab hadh kar de!'</b>
                    </p>

                    <p onClick={handleShowTNC} className="instructionRules">
                        <b>
                            <u>Contest Rules</u>
                        </b>
                    </p>
                </div> */}
                <div className="instructionSteps">
                    <div className="step">
                        <div className="icon step1Icon"></div>
                        <div className="stepText">
                            Start recording your video
                        </div>
                    </div>
                    <div className="step">
                        <div className="icon step2Icon"></div>
                        <div className="stepText">
                            Shout <b>'India Ab Hadh Kar De'</b>
                            <br /> as loud as you can
                        </div>
                    </div>
                    <div className="step">
                        <div className="icon shareIcon step3Icon"></div>
                        <div className="stepText">
                            Submit, Register & Share{" "}
                        </div>
                    </div>
                </div>
                <p onClick={handleShowRules} className="instructionRules centerX">
                    *Contest rules apply
                </p>
                <ActionButton
                    btnID="instructionCheerBtn"
                    btnText="Let's go"
                    onButtonClick={OnProceed}
                />

                <div id="TNCPanel" className="TermsNConditions hidden">
                    <div onClick={handleCloseRules} className="crossTNC"></div>
                    <div id="InstructionRules" className="TNCContainer">
                        <div className="TermsNConditionsText">
                            <span className="TNCTitle">
                                <b>Rules of the Contest (“Rules”)</b>
                            </span>                            
                            <br />
                            <br />
                            DESCRIPTION OF THE CONTEST
                            <br />
                            <br />
                            Opportunity to win prizes mentioned herein below, by
                            submitting a video-cheers for the Indian athletic
                            contingent to the Olympics 2024, Paris.
                            <br />
                            <br />
                            Any and all terms not expressly defined herein shall
                            be as defined under the Terms and Conditions of the
                            Contest available on the Site, available here
                            {" "}<a href="https://hadhkarde.adityabirlacapital.com/">https://hadhkarde.adityabirlacapital.com/</a> <br />
                            <br />
                            ELIGIBILITY FOR PARTICIPATION IN THE CONTEST
                            <br />
                            <br />A person shall be eligible to participate in
                            the Contest if such person fulfils all the following
                            criteria:
                            <br />
                            The participant must be an Indian citizen who is a
                            legal resident of India;
                            <br />
                            The participants must be 18 years of age or older at
                            the time of entry;
                            <br />
                            The participant should not be barred or otherwise
                            legally prohibited from taking part in the Contest;
                            <br />
                            The participant shall not impersonate any person,
                            entity or falsely state or otherwise misrepresent
                            such person’s or entity’s identity;
                            <br />
                            Employees of Aditya Birla Capital Ltd. and its
                            affiliates, advertising agencies, and their
                            immediate families shall be eligible to participate
                            in the Contest. However, they shall not be eligible
                            to receive/win any prize mentioned hereinbelow;
                            <br />
                            If at any time, ABC is of the opinion that you do
                            not satisfy the eligibility criteria specified above
                            or you have made any misrepresentation, ABC retains
                            all rights to disqualify you from participating in
                            the Contest. <br />
                            <br />
                            VALID ENTRY
                            <br />
                            <br />A video entry submitted by you on the Site,
                            for the purposes of the Contest, shall be considered
                            for a prize, only if the following conditions are
                            met (“Valid Entry”):
                            <br />
                            It should not contain any violent, abusive,
                            inappropriate, obscene or graphic content that is
                            not suitable for consumption or viewership,
                            including any content relating to betting/gambling
                            activities, sale of prohibited goods, drug use or
                            alcohol;
                            <br />
                            It should not be in violation or infringement of any
                            third-party intellectual property rights, and should
                            not contain any work that is not created by you or
                            licensed to you, or which you are not entitled to
                            license to us. You must have the right to share such
                            a content or work;
                            <br />
                            It should not be in violation of any applicable law,
                            rule or regulation;
                            <br />
                            It should not promote, support or endorse any
                            dangerous goods, organisations, practices or
                            persons;
                            <br />
                            It should not contain any Sensitive Personal Data or
                            Information.
                            <br />
                            You acknowledge and agree that ABC and/or its
                            affiliates reserve the right to take appropriate
                            action against you for any violation of these terms,
                            or in respect of your use of the Site.
                            <br />
                            <br />
                            CONTEST PERIOD
                            <br />
                            <br />
                            The Contest will be open for entries from July 14th,
                            2024, at 12:00 AM IST to August 10th, 2024, at 11:59
                            PM IST.
                            <br />
                            <br />
                            HOW TO ENTER
                            <br />
                            <br />
                            Visit the official Contest microsite/Site.
                            <br />
                            Submit your name, mobile number (with OTP
                            verification), and email address.
                            <br />
                            Record a Valid Entry with a video cheer for the
                            Indian Olympic athletes – saying ‘India, ab hadh kar
                            de’ in a language of your choice and follow the
                            process on the screens to submit the entry.
                            <br />
                            The Site/ microsite will store the video, voice, and
                            measure the decibel level of your cheer.
                            <br />
                            Users can enter multiple videos to participate. The
                            most creative video with the highest decibel level
                            among your entries, will be selected (at our sole
                            and absolute discretion) as your final submission.
                            The participant shall have no right to challenge any
                            decision with respect to the selection of the
                            winners of the Contest.
                            <br />
                            <br />
                            SELECTION OF WINNERS
                            <br />
                            <br />
                            One (1) winner shall be selected by ABC (or its
                            agent(s)) to win the grand prize, from all Valid
                            Entries, whose video entry is the loudest and/or
                            most creative.
                            <br />
                            Five (5) winners each week will be chosen by ABC (or
                            its agent(s)) to receive other prizes on the basis
                            of the aforesaid criteria.
                            <br />
                            Winners will be notified by email/ phone each week.
                            <br />
                            Grand prize winner will be notified by email by
                            August 20th, 2024 (or at such other date, as may be
                            decided by ABC, at its sole discretion).
                            <br />
                            ABC employees, and its affiliates, advertising
                            agencies, and their immediate families are not
                            eligible to win the prizes stated hereinabove.
                            <br /> <br />
                            PRIZE
                            <br />
                            Grand prize winner will win a trip to Paris for two
                            people (package details are provided in Annexure I
                            to the Terms and Conditions of the Contest).
                            <br />
                            The travel date will be decided by us at our sole
                            discretion and may be between September 15th to
                            November 15th, 2024.
                            <br />
                            Other winners may be given Amazon vouchers, and/or
                            other branded goodies like neck pillow(s), passport
                            cover(s), travel charger(s), etc. (Detailed list of
                            the indicative prizes are provided in Annexure I to
                            the Terms and Conditions of the Contest).
                            <br />
                            The prize cannot be exchanged for cash or any
                            vouchers.
                            <br />
                            <br />
                            CLAIMING YOUR PRIZE
                            <br />A list of winners will be notified by ABC on
                            the ABC social media handles.
                            <br />
                            Winners must claim their prize by providing valid
                            identification documents within one (1) week of
                            receiving the notification email and/or call.
                            <br />
                            Failure to claim the prize within the specified
                            timeframe will result in forfeiture of the prize.
                            <br />
                            <br />
                            ACKNOWLEDGMENTS
                            <br />
                            <br />
                            You agree, acknowledge and confirm that these Rules
                            are subject to the Site’s Terms and Conditions
                            available here <a href="https://hadhkarde.adityabirlacapital.com/">https://hadhkarde.adityabirlacapital.com/</a>, and Privacy
                            Policy available here
                            {" "} <a href="https://www.adityabirlacapital.com/">https://www.adityabirlacapital.com/</a> In case of any
                            conflict between the terms and provisions of these
                            Rules and the Terms and Conditions, the Terms and
                            Conditions shall prevail to the extent of such
                            conflict.
                            <br />
                            By clicking ‘Proceed’, you agree with the Terms and
                            Conditions and Privacy Policy of the Site.
                            <br />
                            This Contest is not being offered or is not
                            applicable to any territory where there is any legal
                            restriction applicable to contests of such nature or
                            the Contest. No prize under this Contest shall be
                            awarded to a person who is residing in any
                            state/territory where offering such Contest is
                            legally prohibited or requires ABC to obtain
                            license/approval/registration.
                            <br />
                            <br />
                            <br />
                        </div>
                        <div
                            className="closeTNC centerX"
                            onClick={handleCloseRules}
                        >
                            <b>Proceed</b>
                        </div>
                    </div>
                </div>

                {/* <HeaderNFooter/>
                <div className="logo centerX"></div>
                <p className="instructionsTitle centerX">Steps To Cheer India</p>   
                <div className="instructionsText centerX"></div>         
                <ActionButton btnID="instructionCheerBtn" btnText="Let's Cheer" onButtonClick={OnProceed} />                 */}
            </div>
        </>
    );
}
