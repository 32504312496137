import { useCallback, useRef, useState } from "react";

import "../Styles/Test.css";

import Webcam from "react-webcam";
import VideoPreview from "../Components/VideoPreview";
import VideoPreviewAndroid from "../Components/VideoPreviewAndroid";
import axios from "axios";

export default function Test() {
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [hasCaptured, setHasCaptured] = useState(false);
    const [capturing, setCapturing] = useState(false);
    var _hasCaptured;
    const [isWebm, setIsWebm] = useState(true);
    const [onScreenLogger, setOnScreenLogger] = useState("");

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStartCaptureClick = useCallback(() => {
        setRecordedChunks([]);
        setCapturing(true);
        document.getElementById("webcamRender").style.display = "block";
        setHasCaptured(false);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/mp4",
        });

        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
    }, [webcamRef, mediaRecorderRef]);

    const TestDownload = () => {
        const fileUrl =
            "https://firebasestorage.googleapis.com/v0/b/abc-olympics-website.appspot.com/o/videos%2F1720525990978_2489.mp4?alt=media&token=7c84d33c-cbbd-4da4-822e-30aa9908dd58";

        fetch(fileUrl, { mode: "no-cors" })
            .then((response) => {
                // response.blob();
                console.log(response);
            })
            .then((blob) => {
                // Now you have the file as a blob!
                // You can use it as needed (e.g., upload to a server, display in a video player, etc.).
                console.log("File as blob:", blob);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };

    const handleStopCaptureClick = useCallback(() => {
        setCapturing(false);
        mediaRecorderRef.current.stop();
        document.getElementById("webcamRender").style.display = "none";
        setHasCaptured(true);
        clearInterval(window.recordingInterval);
    }, [mediaRecorderRef, webcamRef, hasCaptured, recordedChunks]);

    const handleTestShare = () => {
        axios({
            url: "https://firebasestorage.googleapis.com/v0/b/abc-olympics-website.appspot.com/o/videos%2F1720525990978_2489.mp4?alt=media&token=7c84d33c-cbbd-4da4-822e-30aa9908dd58", //your url
            method: "GET",
            responseType: "blob", // important
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", "file.pdf"); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    };

    const handleMediaShare = () => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/mp4",
            });

            console.log(blob.type);
            const file = new File([blob], "test.mp4", { type: "video/mp4" });
            // const videoUrl = URL.createObjectURL(blob);
            if (navigator.share) {
                navigator
                    .share({
                        title: "Check out this video!",
                        text: "Watch this amazing video",
                        files: [file],
                        // url: videoUrl,
                    })
                    .then(() => console.log("Shared successfully"))
                    .catch((error) => console.error("Error sharing:", error));
            } else {
                console.log("Web Share API not supported on this browser.");
            }
        }
    };

    const handleDownloadNShare = useCallback(() => {
        console.log("Starting donwload");
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/mp4",
            });
            const file = new File([blob], "test.mp4", {
                type: blob.type,
                lastModified: new Date().getTime(),
            });

            if (navigator.canShare) {
                navigator
                    .share({
                        files: file,
                        title: "Check out this video!",
                        text: "Watch this amazing video",
                    })
                    .then(() => console.log("Shared successfully"))
                    .catch((error) => console.error("Error sharing:", error));
            } else {
                console.log("Web Share API not supported on this browser.");
            }
        }
    }, [recordedChunks]);

    const videoConstraints = {
        facingMode: "user",
    };

    return (
        <>
            <div className="panel testScreen">
                {/* <div className="screenLogger">{onScreenLogger}</div> */}
                <Webcam
                    id="webcamRender"
                    audio={true}
                    screenshotFormat="image/png"
                    videoConstraints={videoConstraints}
                    mirrored={true}
                    ref={webcamRef}
                    muted={true}
                    style={{
                        height: "100vh",
                        width: "auto",
                        objectFit: "fill",
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                    }}
                />

                {recordedChunks.length > 0 && hasCaptured && (
                    <VideoPreview previewChunks={recordedChunks} />
                )}
                {!capturing ? (
                    <button
                        className="testBtn"
                        onClick={handleStartCaptureClick}
                    >
                        Start{" "}
                    </button>
                ) : (
                    <button
                        className="testBtn2"
                        onClick={handleStopCaptureClick}
                    >
                        Stop{" "}
                    </button>
                )}

                {recordedChunks.length > 0 && hasCaptured && (
                    <button
                        className="testBtn3 centerX"
                        onClick={handleMediaShare}
                    >
                        Share
                    </button>
                )}

                {/* <button className="testBtn3 centerX" onClick={TestDownload}>
                    download
                </button> */}
            </div>
        </>
    );
}
