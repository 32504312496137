import { useCallback, useEffect, useRef, useState } from "react";
import { logEvent } from "firebase/analytics";
import Webcam from "react-webcam";
import "../Styles/WebcamRecorder.css";
import ActionButton from "./ActionButton";
import CircularLoadingBar from "./CircularLoadingBar";
import VideoPreview from "./VideoPreview";
import "../Styles/Loader.css";
import {
    ref,
    getStorage,
    uploadBytes,
    uploadBytesResumable,
    getDownloadURL,
    getBlob,
} from "firebase/storage";
import {
    firebaseStorage,
    UpdateUserDetail,
    firebaseAnalytics,
} from "../Utils/firebase";
import VideoPreviewAndroid from "./VideoPreviewAndroid";

export default function WebcamRecorder({
    onMediaReady,
    onRecordingSubmitted,
    onRecordingStarted,
    onRecordingStopped,
}) {
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [hasCaptured, setHasCaptured] = useState(false);
    const [progress, setProgress] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadStatus, setUploadStatus] = useState(false);
    const [userVideoUrl, setUserVideoUrl] = useState(null);
    const [canCapture, setCanCapture] = useState(false);
    const [clockTimer, setClockTimer] = useState(20);
    const [onScreenLogger, setOnScreenLogger] = useState("");
    const [canShowPreview, setCanShowPreview] = useState(true);
    var _hasCaptured = false;
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const [isIOS, setIsIOS] = useState(false);

    const OnUserMediaReady = () => {
        document.getElementById("recordShoutHeader").style.display = "block";
        onMediaReady();
        setCanCapture(true);
    };

    const handleMediaShare = () => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/mp4",
            });

            // console.log(blob.type);
            const file = new File([blob], "test.mp4", { type: blob.type });
            // const videoUrl = URL.createObjectURL(blob);
            if (navigator.share) {
                navigator
                    .share({
                        title: "Check out this video!",
                        text: "Watch this amazing video",
                        files: [file],
                        // url: videoUrl,
                    })
                    .then(() => console.log("Shared successfully"))
                    .catch((error) => console.error("Error sharing:", error));
            } else {
                console.log("Web Share API not supported on this browser.");
            }
        }
    };

    const progressBarInterval = (prevProgress) => {
        const duration = 20000;
        if (!_hasCaptured && prevProgress < 100) {
            return prevProgress + (100 / duration) * 100;
        }
        clearInterval(window.recordingInterval); // Stop when progress reaches 100%
        handleStopCaptureClick();
        return prevProgress;
    };

    const startProgress = () => {
        // Increment the progress every second for 15 seconds
        window.recordingInterval = setInterval(() => {
            setProgress((prevProgress) => {
                const duration = 20000;
                if (!_hasCaptured && prevProgress < 100) {
                    const newTime = prevProgress + (100 / duration) * 100;
                    setClockTimer(Math.floor(21 - newTime * 0.2));
                    return newTime;
                }
                clearInterval(window.recordingInterval); // Stop when progress reaches 100%
                handleStopCaptureClick();
                return prevProgress;
            });
        }, 100);
    };

    const handleStartCaptureClick = useCallback(() => {
        // console.log("start callback called");
        logEvent(firebaseAnalytics, "Started recording");
        document.getElementById("recordShoutHeader").style.display = "none";
        setRecordedChunks([]);
        onRecordingStarted();
        document.getElementById("webcamRender").style.display = "block";
        setHasCaptured(false);
        _hasCaptured = false;
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/mp4",
        });

        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
        startProgress();
    }, [webcamRef, mediaRecorderRef]);

    const resetProgress = () => {
        setProgress(0);
    };

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStopCaptureClick = useCallback(() => {

        mediaRecorderRef.current.stop();
        // document.getElementById("webcamRender").style.display = "none";
        setCapturing(false);
        onRecordingStopped();
        // setTimeout(()=>{setHasCaptured(true);}, 5000)
        setHasCaptured(true);
        
        clearInterval(window.recordingInterval);        
    }, [mediaRecorderRef, webcamRef, recordedChunks, hasCaptured]);

    const handleRetry = useCallback(() => {
        // console.log("retry callback called");
        logEvent(firebaseAnalytics, "Retried capture");
        setHasCaptured(false);
        _hasCaptured = false;
        setRecordedChunks([]);
        document.getElementById("recordShoutHeader").style.display = "block";
        resetProgress();        
        document.getElementById("webcamRender").style.display = "block";
    });

    const handleDownload = useCallback(() => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/mp4",
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "soundToParisCheer.mp4";
            a.click();
            window.URL.revokeObjectURL(url);
        }
    }, [recordedChunks]);

    const handleUpload = useCallback(async () => {
        logEvent(firebaseAnalytics, "Started upload video");
        // console.log(window.highestDecibels + "highest decibel");
        if (window.highestDecibels < 20) {
            alert(
                `Uh-oh! Too low!
Cheer Louder!`
            );
            return;
        }

        setCanCapture(false);
        if (recordedChunks.length) {
            let blob;
            let ext;
            if (isIOS) {
                blob = new Blob(recordedChunks, {
                    type: "video/mp4",
                });
                ext = ".mp4";
            } else {
                blob = new Blob(recordedChunks, {
                    type: "video/webm",
                });
                ext = ".webm";
            }

            const storageRef = ref(
                firebaseStorage,
                "videos/" +
                    Date.now() +
                    "_" +
                    Math.floor(Math.random() * 10000) +
                    ext
            );

            try {
                // Upload Bolb using uploadBytes
                setUploadStatus(true);

                uploadBytes(storageRef, blob).then((snapshot) => {
                    setCanCapture(true);
                    setUploadStatus(false);
                    onRecordingSubmitted();        
                    getDownloadURL(snapshot.ref)
                        .then((downloadUrl) => {
                            setUserVideoUrl(downloadUrl);
                            UpdateUserDetail(downloadUrl);
                            onRecordingSubmitted();
                            logEvent(
                                firebaseAnalytics,
                                "Uploaded video successfully"
                            );
                        })
                        .catch((error) => {
                            console.error("Error getting download URL:", error);
                        });
                });
            } catch (error) {
                alert("Error uploading video");
                setOnScreenLogger(error);
                console.error("Error uploading video:", error);
            }
        }
    }, [recordedChunks, isIOS, onRecordingSubmitted]);

    const OnUserMediaError = () => {
        alert("Cannot access webcam.");
    };

    useEffect(() => {
        let details = navigator.userAgent;

        let regex = /iphone|Macintosh/i;
        let isIPhone = regex.test(details);

        if (isIPhone) {
            setIsIOS(true);
            setOnScreenLogger("is IOS");
        } else {
            setIsIOS(false);
            setOnScreenLogger("is not IOS");
        }
    }, [isIOS]);

    const videoConstraints = {
        facingMode: "user",
    };

    return (
        <>
        {/* <div className="screenLogger">{onScreenLogger}</div> */}
            <div className="webcamComponent centerX">
                <Webcam
                    id="webcamRender"
                    audio={true}
                    onUserMedia={OnUserMediaReady}
                    onUserMediaError={OnUserMediaError}
                    screenshotFormat="image/png"
                    videoConstraints={videoConstraints}
                    mirrored={true}
                    ref={webcamRef}
                    muted={true}
                    style={{
                        height: "100svh",
                        width: "auto",
                        objectFit: "fill",
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                    }}
                />
            </div>
            {recordedChunks.length > 0 && hasCaptured && isIOS && (
                <VideoPreview previewChunks={recordedChunks} />
            )}

            {recordedChunks.length > 0 && hasCaptured && !isIOS && (
                <VideoPreviewAndroid previewChunks={recordedChunks} />
            )}

            {canCapture && !hasCaptured && (
                <div className="recordBtnOuter centerX">
                    <CircularLoadingBar progressnumber={progress} />
                </div>
            )}

            {canCapture && capturing && (
                <div
                    id="stopBtn"
                    className="recordBtn recordBtnStop centerX"
                    onClick={handleStopCaptureClick}
                ></div>
            )}

            {canCapture && !capturing && !hasCaptured && (
                <ActionButton
                    btnID="startBtn"
                    btnText=""
                    onButtonClick={handleStartCaptureClick}
                    btnClass="recordBtn recordBtnStart centerX"
                />
            )}
            {canCapture && recordedChunks.length > 0 && (
                <ActionButton
                    btnID="uploadBtn"
                    btnText="Next"
                    onButtonClick={handleUpload}
                    btnClass="uploadBtn"
                />
            )}

            {canCapture && recordedChunks.length > 0 && (
                <ActionButton
                    btnID="retryBtn"
                    btnText="Try Again"
                    onButtonClick={handleRetry}
                    btnClass="retryBtn"
                />
            )}

            {uploadStatus && (
                <div className="loading centerY centerX">
                    <div className="loading-bar"></div>
                    <div className="loading-bar"></div>
                    <div className="loading-bar"></div>
                    <div className="loading-bar"></div>
                </div>
            )}
        </>
    );
}
