import React from "react";
import "../Styles/CircularLoadingBar.css";

export default function CircularLoadingBar({ progressnumber }) {
    // Calculate the stroke-dashoffset value
    const convertedProgress = 250 - (progressnumber * 250) / 100;

    // // Determine the color based on progress
    // let strokeColor;
    // if (progressnumber <= 33) {
    //     strokeColor = "#f50303";
    // } else if (progressnumber <= 50) {
    //     strokeColor = "#ffc107";
    // } else {
    //     strokeColor = "#4caf50";
    // }

    // Apply styles
    const progressBarStyles = {
        strokeDashoffset: `${convertedProgress}%`,
        stroke: "#b72436",
    };

    return (
        <>
            <div className="svgwrapper">
                <svg viewBox="0 0 100 100">
                    <circle
                        id="progressbar"
                        cx="50"
                        cy="50"
                        r="40"
                        fill="transparent"
                        strokeWidth="10"
                        stroke="#e0e0de"
                    />
                    <circle
                        cx="50"
                        cy="50"
                        r="40"
                        fill="transparent"
                        strokeWidth="10"
                        strokeDasharray="251"
                        style={progressBarStyles}
                    />
                </svg>
            </div>
        </>
    );
}
