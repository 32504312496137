import "../Styles/ActionButton.css"

export default function ActionButton({isbold,btnText = "Button", btnID, btnClass="actionButton", onButtonClick}) {
    
  return (
    <>
      {isbold ? (<button id={btnID} className={btnClass} onClick={onButtonClick}><b>{btnText}</b></button>):<button id={btnID} className={btnClass} onClick={onButtonClick}>{btnText}</button>}
        
    </>
  )
}
