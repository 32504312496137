import HeaderNFooter from "../Components/HeaderNFooter";
import "../Styles/FormPanel.css";
import { firebaseAnalytics } from "../Utils/firebase";
import { logEvent } from "firebase/analytics";
import { WriteUserData } from "../Utils/firebase";
import { useEffect, useState } from "react";
import { getDatabase, ref, set, get, child } from "firebase/database";

export default function FormPanel({ nextPage }) {
    const [isABCEmployee, setIsABCEmployee] = useState(false);
    const [ABCDept, setABCDept] = useState(null);

    const handleShowTNC = () => {
        logEvent(firebaseAnalytics, "Viewed Terms and Conditions");
        document.getElementById("TNCPanel").classList.remove("hidden");
    };

    const handleCloseTNC = () => {
        logEvent(firebaseAnalytics, "Clicked on TNC understood");
        document.getElementById("TNCContainer").scrollTop = 0;
        document.getElementById("TNCPanel").classList.add("hidden");
    };

    var inputs = document.querySelectorAll("input");

    // Loop through all input elements and assign the onfocus event
    inputs.forEach(function (input) {
        input.addEventListener("focus", function () {
            window.scrollTo(0, 0);
            document.body.scrollTop = 0;
        });
        input.addEventListener("blur", function () {
            window.scrollTo(0, 0);
            document.body.scrollTop = 0;
        });
    });    

    const SignUpUser = async (e) => {
        const name = document.getElementById("nameInput");
        const email = document.getElementById("emailInput");
        const phone = document.getElementById("mobileInput");
        const form = document.getElementById("CTAForm");

        e.preventDefault();
        document.getElementById("CTASubmitBtn").disabled = true;
        const validStarts = ["6", "7", "8", "9"];
        if (!validStarts.includes(phone.value[0])) {
            alert("please enter a valid number");
            document.getElementById("CTASubmitBtn").disabled = false;
            return;
        }
        const dbRef = ref(getDatabase());
        get(child(dbRef, "UserRates/" + phone.value + "/"))
            .then((snapshot) => {
                if (snapshot.val() !== null) {
                    const cooldownPeriod = 60 * 60 * 1000;
                    // const testCooldownPeriod = 10 * 1000;

                    const reqDiff = Math.abs(
                        Date.now() - snapshot.val().lastRequest
                    );

                    if (reqDiff < cooldownPeriod) {
                    } else {
                        const db = getDatabase();
                        set(ref(db, "UserRates/" + phone.value + "/"), {
                            requests: 1,
                            lastRequest: Date.now(),
                        });
                        snapshot.val().requests = 1;
                    }

                    if (snapshot.val().requests > 10) {
                        throw "too many request.";
                    }
                    var updatedReq = snapshot.val().requests + 1;
                    const db = getDatabase();
                    set(ref(db, "UserRates/" + phone.value + "/"), {
                        requests: updatedReq,
                        lastRequest: Date.now(),
                    });
                    // setUsername(name.value);
                    WriteUserData(
                        name.value,
                        email.value,
                        phone.value,
                        ABCDept
                    ).then((status) => {   
                        console.log("Name: " + name.value + " \nStatus: " + status +  " \nEmail:" + email.value + " \nPhone: " + `+91${phone.value}`);                                                                  
                        window.clevertap.onUserLogin.push({
                            "Site": {
                                "Name": name.value,            // String
                                "Identity": status,              // String or number
                                "Email": email.value,         // Email address of the user
                                "Phone": `+91${phone.value}`,           // Phone (with the country code)
                                "MSG-whatsapp": true,              // Enable WhatsApp notifications
                            }
                        })
                        logEvent(
                            firebaseAnalytics,
                            "Created user successfully."
                        );
                        nextPage();
                        form.reset();
                    });
                    // nextPage();
                } else {
                    const db = getDatabase();
                    set(ref(db, "UserRates/" + phone.value + "/"), {
                        requests: 1,
                        lastRequest: Date.now(),
                    });
                    // setUsername(name.value);
                    WriteUserData(
                        name.value,
                        email.value,
                        phone.value,
                        ABCDept
                    ).then((status) => {
                        logEvent(
                            firebaseAnalytics,
                            "Created user successfully."
                        );
                        form.reset();
                        nextPage();
                    });
                }
            })
            .catch((err) => {
                alert(err);
                document.getElementById("CTASubmitBtn").disabled = false;
            });
    };

    const OnEmployeeStatusChange = (e) => {
        if (e.currentTarget.value === "Yes") {
            setIsABCEmployee(true);
            setABCDept("ABC & ABFSSL")
        } else {
            setIsABCEmployee(false);
            setABCDept("");
        }
    };

    const OnDeptChange = (e) => {
        console.log(e.target.value);
        setABCDept(e.target.value);
    };

    const OnTNCAgreed = (e) => {
        if (e.currentTarget.checked) {
            document.getElementById("CTASubmitBtn").style.backgroundColor =
                "#b72436";
            document.getElementById("CTASubmitBtn").disabled = false;
        } else {
            document.getElementById("CTASubmitBtn").style.backgroundColor =
                "#888888";
            document.getElementById("CTASubmitBtn").disabled = true;
        }
    };

    const handleShowRules = () => {
        logEvent(firebaseAnalytics, "Viewed Terms and Conditions");
        document.getElementById("contestRules").classList.remove("hidden");
    };

    const handleCloseRules = () => {
        logEvent(firebaseAnalytics, "Clicked on TNC understood");
        document.getElementById("InstructionRules").scrollTop = 0;
        document.getElementById("contestRules").classList.add("hidden");
    };

    return (
        <>
            <div className="panel formScreen centerX">
                <div id="recaptcha-container"></div>
                <HeaderNFooter />

                <div className="formBG centerX">
                    <form
                        id="CTAForm"
                        className="inputForm centerX"
                        onSubmit={SignUpUser}
                    >
                        <h1 className="formTitle">
                            Submit Your Cheer and Win Big!
                        </h1>
                        <p className="formDesc">
                            {/* If your cheer records to be the loudest, the
                            following information will help us contact you with
                            your ticket to Paris. */}
                            Stand a chance to win a trip to Paris and other
                            exciting prizes *
                        </p>
                        <input
                            type="text"
                            id="nameInput"
                            placeholder="*Name"
                            className="inputField"
                            pattern="[A-Za-z\s]*"
                            minLength={3}
                            maxLength={30}
                            required
                        />
                        <input
                            type="email"
                            id="emailInput"
                            placeholder="*Email"
                            className="inputField"
                            required
                        />
                        <div className="mobileDiv">
                            <input
                                type="text"
                                id="mobileInput"
                                placeholder="*Mobile No."
                                pattern="[0-9]*"
                                className="inputField centerX"
                                // onChange={OnMobileInputChanged}
                                maxLength={10}
                                minLength={10}
                                required
                            />
                            {/* <button onClick={VerifyMobile} id="verifyBtn">
                                Verify
                            </button> */}
                        </div>

                        <div className="IsEmployee">
                            <p className="isEmployeeTxt">
                                Are you an Aditya Birla Capital employee?
                            </p>
                            <div className="radioInput">
                                <input
                                    type="radio"
                                    name="IsABCEmployee"
                                    value="Yes"
                                    onChange={OnEmployeeStatusChange}
                                    required
                                />
                                <span className="radioLabel">Yes</span>
                            </div>

                            <div className="radioInput">
                                <input
                                    type="radio"
                                    name="IsABCEmployee"
                                    value="No"
                                    onChange={OnEmployeeStatusChange}
                                    required
                                />
                                <span className="radioLabel">No</span>
                            </div>
                        </div>

                        {isABCEmployee && (
                            <div id="employeeOptions">
                                <div className="button dropdown">
                                    <select
                                        id="colorselector"
                                        onChange={OnDeptChange}
                                    >
                                        <option value="ABC & ABFSSL">
                                            ABC & ABFSSL
                                        </option>
                                        <option value="ABFL">ABFL</option>
                                        <option value="ABG">ABG</option>
                                        <option value="ABIBL">ABIBL</option>
                                        <option value="ABHFL">ABHFL</option>
                                        <option value="ABSLAMC">ABSLAMC</option>
                                        <option value="ABML">ABML</option>
                                        <option value="ABCD">ABCD</option>
                                        <option value="ABSLI & ABSLPM">
                                            ABSLI & ABSLPM
                                        </option>
                                        <option value="ABHI & ABW">
                                            ABHI & ABW
                                        </option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                            </div>
                        )}

                        <div className="TNC">
                            <input
                                type="checkbox"
                                name="TNCAgreeBox"
                                id="TNCAgreeBox"
                                onChange={OnTNCAgreed}
                                required
                            />
                            <p className="TNCText">
                                I acknowledge that I have read, understood, and
                                agree to be bound by the
                                <span
                                    style={{ marginLeft: "1svh" }}
                                    onClick={handleShowTNC}
                                >
                                    <u>
                                        <b>
                                            <i>Terms and Conditions</i>
                                        </b>
                                    </u>
                                </span>
                                <span style={{ marginLeft: "1svh" }}>
                                    <b>&</b>
                                </span>
                                <span
                                    style={{ marginLeft: "1svh" }}
                                    onClick={handleShowRules}
                                >
                                    <u>
                                        <b>
                                            <i>Contest Rules</i>
                                        </b>
                                    </u>
                                </span>
                            </p>
                        </div>

                        <button id="CTASubmitBtn" type="submit" disabled>
                            Register
                        </button>
                    </form>
                </div>

                <div className="players centerX"></div>
                <div id="contestRules" className="TermsNConditions hidden">
                    <div onClick={handleCloseRules} className="crossTNC"></div>
                    <div id="InstructionRules" className="TNCContainer">
                        <div className="TermsNConditionsText">
                            <span className="TNCTitle">
                                <b>Rules of the Contest (“Rules”)</b>
                            </span>
                            <br />
                            <br />
                            DESCRIPTION OF THE CONTEST
                            <br />
                            <br />
                            Opportunity to win prizes mentioned herein below, by
                            submitting a video-cheers for the Indian athletic
                            contingent to the Olympics 2024, Paris.
                            <br />
                            <br />
                            Any and all terms not expressly defined herein shall
                            be as defined under the Terms and Conditions of the
                            Contest available on the Site, available here{" "}
                            <a href="https://hadhkarde.adityabirlacapital.com/">
                                https://hadhkarde.adityabirlacapital.com/
                            </a>{" "}
                            <br />
                            <br />
                            ELIGIBILITY FOR PARTICIPATION IN THE CONTEST
                            <br />
                            <br />A person shall be eligible to participate in
                            the Contest if such person fulfils all the following
                            criteria:
                            <br />
                            The participant must be an Indian citizen who is a
                            legal resident of India;
                            <br />
                            The participants must be 18 years of age or older at
                            the time of entry;
                            <br />
                            The participant should not be barred or otherwise
                            legally prohibited from taking part in the Contest;
                            <br />
                            The participant shall not impersonate any person,
                            entity or falsely state or otherwise misrepresent
                            such person’s or entity’s identity;
                            <br />
                            Employees of Aditya Birla Capital Ltd. and its
                            affiliates, advertising agencies, and their
                            immediate families shall be eligible to participate
                            in the Contest. However, they shall not be eligible
                            to receive/win any prize mentioned hereinbelow;
                            <br />
                            If at any time, ABC is of the opinion that you do
                            not satisfy the eligibility criteria specified above
                            or you have made any misrepresentation, ABC retains
                            all rights to disqualify you from participating in
                            the Contest. <br />
                            <br />
                            VALID ENTRY
                            <br />
                            <br />A video entry submitted by you on the Site,
                            for the purposes of the Contest, shall be considered
                            for a prize, only if the following conditions are
                            met (“Valid Entry”):
                            <br />
                            It should not contain any violent, abusive,
                            inappropriate, obscene or graphic content that is
                            not suitable for consumption or viewership,
                            including any content relating to betting/gambling
                            activities, sale of prohibited goods, drug use or
                            alcohol;
                            <br />
                            It should not be in violation or infringement of any
                            third-party intellectual property rights, and should
                            not contain any work that is not created by you or
                            licensed to you, or which you are not entitled to
                            license to us. You must have the right to share such
                            a content or work;
                            <br />
                            It should not be in violation of any applicable law,
                            rule or regulation;
                            <br />
                            It should not promote, support or endorse any
                            dangerous goods, organisations, practices or
                            persons;
                            <br />
                            It should not contain any Sensitive Personal Data or
                            Information.
                            <br />
                            You acknowledge and agree that ABC and/or its
                            affiliates reserve the right to take appropriate
                            action against you for any violation of these terms,
                            or in respect of your use of the Site.
                            <br />
                            <br />
                            CONTEST PERIOD
                            <br />
                            <br />
                            The Contest will be open for entries from July 14th,
                            2024, at 12:00 AM IST to August 10th, 2024, at 11:59
                            PM IST.
                            <br />
                            <br />
                            HOW TO ENTER
                            <br />
                            <br />
                            Visit the official Contest microsite/Site.
                            <br />
                            Submit your name, mobile number (with OTP
                            verification), and email address.
                            <br />
                            Record a Valid Entry with a video cheer for the
                            Indian Olympic athletes – saying ‘India, ab hadh kar
                            de’ in a language of your choice and follow the
                            process on the screens to submit the entry.
                            <br />
                            The Site/ microsite will store the video, voice, and
                            measure the decibel level of your cheer.
                            <br />
                            Users can enter multiple videos to participate. The
                            most creative video with the highest decibel level
                            among your entries, will be selected (at our sole
                            and absolute discretion) as your final submission.
                            The participant shall have no right to challenge any
                            decision with respect to the selection of the
                            winners of the Contest.
                            <br />
                            <br />
                            SELECTION OF WINNERS
                            <br />
                            <br />
                            One (1) winner shall be selected by ABC (or its
                            agent(s)) to win the grand prize, from all Valid
                            Entries, whose video entry is the loudest and/or
                            most creative.
                            <br />
                            Five (5) winners each week will be chosen by ABC (or
                            its agent(s)) to receive other prizes on the basis
                            of the aforesaid criteria.
                            <br />
                            Winners will be notified by email/ phone each week.
                            <br />
                            Grand prize winner will be notified by email by
                            August 20th, 2024 (or at such other date, as may be
                            decided by ABC, at its sole discretion).
                            <br />
                            ABC employees, and its affiliates, advertising
                            agencies, and their immediate families are not
                            eligible to win the prizes stated hereinabove.
                            <br /> <br />
                            PRIZE
                            <br />
                            Grand prize winner will win a trip to Paris for two
                            people (package details are provided in Annexure I
                            to the Terms and Conditions of the Contest).
                            <br />
                            The travel date will be decided by us at our sole
                            discretion and may be between September 15th to
                            November 15th, 2024.
                            <br />
                            Other winners may be given Amazon vouchers, and/or
                            other branded goodies like neck pillow(s), passport
                            cover(s), travel charger(s), etc. (Detailed list of
                            the indicative prizes are provided in Annexure I to
                            the Terms and Conditions of the Contest).
                            <br />
                            The prize cannot be exchanged for cash or any
                            vouchers.
                            <br />
                            <br />
                            CLAIMING YOUR PRIZE
                            <br />A list of winners will be notified by ABC on
                            the ABC social media handles.
                            <br />
                            Winners must claim their prize by providing valid
                            identification documents within one (1) week of
                            receiving the notification email and/or call.
                            <br />
                            Failure to claim the prize within the specified
                            timeframe will result in forfeiture of the prize.
                            <br />
                            <br />
                            ACKNOWLEDGMENTS
                            <br />
                            <br />
                            You agree, acknowledge and confirm that these Rules
                            are subject to the Site’s Terms and Conditions
                            available here{" "}
                            <a href="https://hadhkarde.adityabirlacapital.com/">
                                https://hadhkarde.adityabirlacapital.com/
                            </a>
                            , and Privacy Policy available here{" "}
                            <a href="https://www.adityabirlacapital.com/">
                                https://www.adityabirlacapital.com/
                            </a>{" "}
                            In case of any conflict between the terms and
                            provisions of these Rules and the Terms and
                            Conditions, the Terms and Conditions shall prevail
                            to the extent of such conflict.
                            <br />
                            By clicking ‘Proceed’, you agree with the Terms and
                            Conditions and Privacy Policy of the Site.
                            <br />
                            This Contest is not being offered or is not
                            applicable to any territory where there is any legal
                            restriction applicable to contests of such nature or
                            the Contest. No prize under this Contest shall be
                            awarded to a person who is residing in any
                            state/territory where offering such Contest is
                            legally prohibited or requires ABC to obtain
                            license/approval/registration.
                            <br />
                            <br />
                            <br />
                        </div>
                        <div
                            className="closeTNC centerX"
                            onClick={handleCloseTNC}
                        >
                            <b>Proceed</b>
                        </div>
                    </div>
                </div>

                <div id="TNCPanel" className="TermsNConditions hidden">
                    <div onClick={handleCloseTNC} className="crossTNC"></div>
                    <div id="TNCContainer" className="TNCContainer">
                        <div className="TermsNConditionsText">
                            <span className="TNCTitle">
                                <b>Terms and conditions</b>
                            </span>
                            <br />
                            Table of Contents <br />
                            <br />
                            1. INTRODUCTORY TERMS <br />
                            2. DEFINITIONS <br />
                            4. VALID ENTRY <br />
                            5. CONTEST PERIOD <br />
                            6. HOW TO ENTER <br />
                            7. SELECTION OF WINNERS <br />
                            8. PRIZE <br />
                            9. CLAIMING YOUR PRIZE <br />
                            10. GENERAL CONDITIONS <br />
                            11. ADDITIONAL TERMS <br />
                            12. INDEMNITY <br />
                            13. LIMITATION OF LIABILITY <br />
                            14. GRIEVANCE REDRESSAL <br />
                            15. INTELLECTUAL PROPERTY RIGHTS <br />
                            16. TERMINATION <br />
                            17. MISCELLANEOUS PROVISIONS <br />
                            <br />
                            <br />
                            INTRODUCTORY TERMS
                            <br />
                            <br />
                            These Terms and Conditions (“Terms”) govern
                            participation by you (“user”, “you” or “your”) in
                            the Contest (defined below) organized by Aditya
                            Birla Capital (by itself or through its agent)
                            (“ABC”, “we”, “us” or “our”), a company established
                            under the laws of India, having its corporate office
                            at One World Center, Tower 1, Jupiter Mills
                            Compound, 841, Senapati Bapat Marg, Elphinstone
                            Road, Mumbai - 400013. Please read these Terms
                            carefully before taking part in the Contest and
                            using the Site (as defined below). These Terms also
                            include our Privacy Policy, available at Privacy
                            Policy | Aditya Birla Capital (“Privacy Policy”).
                            <br />
                            Your access and use of the [●] and act of taking
                            part in the Contest constitutes your agreement to be
                            bound by these Terms, which establishes a
                            contractual relationship between you and ABC. If you
                            do not agree to these Terms, do not access and use
                            the Site and do not take part in the Contest.
                            <br />
                            We may amend the Terms at any time by posting an
                            updated version on the [please provide T&C the link
                            here] or otherwise. The updated version of the Terms
                            shall take effect immediately and it is your
                            responsibility to review these Terms periodically
                            for updates / changes. Your continued participation
                            in the Contest following the changes shall be deemed
                            to mean that you accept and agree to the revisions
                            including additional Terms or removal of portions of
                            these Terms, modifications, etc. As long as you
                            comply with these Terms, we grant you a personal,
                            non-exclusive, non-transferable, limited privilege
                            to take part in the Contest.
                            <br />
                            Taking part in the Contest indicates your consent to
                            these Terms; however, we strongly recommend you to
                            read the terms carefully before participating in the
                            Contest.
                            <br />
                            For the purpose of further clarity, this document is
                            an electronic record in terms of the Information
                            Technology Act, 2000, (“IT Act”) and the amendments
                            thereof from time to time and the rules thereunder
                            as applicable, and the amended provisions pertaining
                            to electronic records in various statutes as amended
                            by the IT Act. This electronic record is generated
                            by a computer system and does not require any
                            physical or digital signatures.
                            <br />
                            You hereby consent to these Terms, and the privacy
                            policy of ABC available at{" "}
                            <a href="https://www.adityabirlacapital.com/">
                                https://www.adityabirlacapital.com/
                            </a>
                            .<br />
                            <br />
                            DEFINITIONS
                            <br />
                            <br />
                            “Applicable Law” means any statute, law, regulation,
                            ordinance, rule, judgment, notification, order,
                            decree, by-law, permit, licenses, approvals,
                            consents, authorisations, government approvals,
                            directives, guidelines, requirements, or other
                            governmental restrictions, or any similar form or
                            decision of, or determination by, or any
                            interpretation, policy or administration, having the
                            force of the law of any of the foregoing, by any
                            Authority, whether in effect as on the date of you
                            agreeing to be bound by these Terms or at any time
                            thereafter.
                            <br />
                            “Authority” means any statutory, judicial,
                            quasi-judicial, regulatory, governmental,
                            administrative authority or self-regulatory
                            authority, or government department, agency,
                            commission, board, rule or regulation-making-entity
                            or authority having or purporting to have
                            jurisdiction over you or us, or other subdivision
                            thereof to the extent that the rules, regulations,
                            standards, requirements, procedures, or orders of
                            such authority, body, or organisation have the force
                            of any Applicable Law or any court or tribunal
                            having jurisdiction.
                            <br />
                            “Contest” shall mean the “Loudest Cheer to Paris”
                            contest hosted and managed by ABC (or its
                            agents/representatives).
                            <br />
                            “Site” shall mean the microsite owned and operated
                            by Aditya Birla Capital, its agents,
                            representatives, partners, vendors and/or
                            affiliates.
                            <br />
                            “SPDI”/ “Sensitive Personal Data or Information”
                            shall mean Sensitive Personal Data or Information,
                            as defined under the Information Technology
                            (Reasonable Security Practices and Procedures and
                            Sensitive Personal Data or Information) Rules, 2011
                            and shall include all other Applicable Laws with
                            respect to data privacy.
                            <br />
                            <br />
                            ELIGIBILITY FOR PARTICIPATION IN THE CONTEST
                            <br />
                            <br />A person shall be eligible to participate in
                            the Contest if such person fulfils the following
                            criteria:
                            <br />
                            The participant must be an Indian citizen who is a
                            legal resident of India;
                            <br />
                            The participants must be 18 years of age or older at
                            the time of entry;
                            <br />
                            The participant should not be barred or otherwise
                            legally prohibited from taking part in the Contest;
                            and
                            <br />
                            The participant shall not impersonate any person,
                            entity or falsely state or otherwise misrepresent
                            such person’s or entity’s identity.
                            <br />
                            Employees of Aditya Birla Capital Ltd. and its
                            affiliates, advertising agencies, and their
                            immediate families shall be eligible to participate
                            in the Contest. However, they shall not be eligible
                            to receive/win any prize mentioned hereinbelow.
                            <br />
                            It is hereby clarified that, subject to Clause 3.2
                            above, the Contest mechanism and Terms and
                            conditions of this Contest shall be applicable on
                            the employees of ABC and its affiliates, advertising
                            agencies, and their immediate families in the same
                            manner as it would be applicable to all other
                            participants.
                            <br />
                            If at any time, ABC is of the opinion that you do
                            not satisfy the eligibility criteria specified above
                            or you have made any misrepresentation, ABC retains
                            all rights to disqualify you from participating in
                            the Contest. <br />
                            <br />
                            VALID ENTRY
                            <br />
                            <br />A video entry submitted by you on the Site,
                            for the purposes of the Contest, shall be considered
                            for a prize, only if the following conditions are
                            met (“Valid Entry”):
                            <br />
                            It should not contain any violent, abusive,
                            inappropriate, obscene or graphic content that is
                            not suitable for consumption or viewership,
                            including any content relating to betting/ gambling
                            activities, sale of prohibited goods, drug use or
                            alcohol;
                            <br />
                            It should not be in violation or infringement of any
                            third-party intellectual property rights and should
                            not contain any work that is not created by you or
                            licensed to you, or which you are not entitled to
                            license to us. You must have the right to share such
                            a content or work;
                            <br />
                            It should not be in violation of any applicable law,
                            rule or regulation;
                            <br />
                            It should not promote, support or endorse any
                            dangerous goods, organisations, practices or
                            persons; and
                            <br />
                            It should not contain any SPDI.
                            <br />
                            You acknowledge and agree, that ABC and/ or its
                            affiliates reserve the right to take appropriate
                            action against you for any violation of these terms,
                            or in respect of your use of the Site.
                            <br />
                            <br />
                            CONTEST PERIOD
                            <br />
                            <br />
                            The Contest will be open for entries from July 14th,
                            2024, at 12:00 AM IST to August 15th, 2024, at 11:59
                            PM IST.
                            <br />
                            <br />
                            HOW TO ENTER
                            <br />
                            <br />
                            Visit the official Contest microsite/ Site.
                            <br />
                            Submit your name, mobile number (with OTP
                            verification), and email address.
                            <br />
                            Record a Valid Entry with a video cheer for the
                            Indian Olympic athletes – saying ‘India, ab hadh kar
                            de’ in a language of your choice and follow the
                            process on the screens to submit the entry.
                            <br />
                            The Site/ microsite will store the video, voice, and
                            measure the decibel level of your cheer.
                            <br />
                            Users can enter multiple videos to participate. The
                            most creative video with the highest decibel level
                            among your entries, will be selected (at our sole
                            and absolute discretion) as your final submission.
                            The participant shall have no right to challenge any
                            decision with respect to selection of winners of the
                            Contest.
                            <br />
                            <br />
                            SELECTION OF WINNERS
                            <br />
                            <br />
                            One (1) winner shall be selected by ABC (or its
                            agent(s)) to win the grand prize, from all Valid
                            Entries, whose video entry is the loudest and/or
                            most creative.
                            <br />
                            Five (5) winners each week will be chosen by ABC (or
                            its agent(s)) to receive other prizes on the basis
                            of the aforesaid criteria.
                            <br />
                            Winners will be notified by email / phone call each
                            week.
                            <br />
                            Grand prize winner will be notified by email by
                            August 20th, 2024 (or at such other date, as may be
                            decided by ABC, at its sole discretion).
                            <br />
                            ABC employees, and its affiliates, advertising
                            agencies, and their immediate families are not
                            eligible to win the prizes stated hereinabove.
                            <br /> <br />
                            PRIZE
                            <br />
                            <br />
                            Subject to the terms and conditions contained
                            herein, Grand prize winner will win a trip to Paris
                            for two people (Details of the prize is mentioned in
                            the Annexure 1).
                            <br />
                            The travel date will be decided by us at our sole
                            discretion and may be between September 15th to
                            November 15th, 2024 (or such other period as may be
                            decided/modified by ABC at its sole discretion).
                            <br />
                            Other winners may be given Amazon vouchers, and/or
                            other branded goodies like neck pillow, passport
                            covers, travel chargers etc. (Detailed list of the
                            indicative prizes are provided in the Annexure 1)
                            <br />
                            The prize cannot be exchanged for cash or any
                            vouchers.
                            <br />
                            <br />
                            CLAIMING YOUR PRIZE
                            <br />
                            <br />
                            Post selection of winners the results will be
                            uploaded on ABC social media handles and ABC/its
                            agents shall also make attempts to contact winners
                            over call/email or other mode of communication to
                            inform about the prizes and further process of
                            claiming the prizes. Winners must claim their prize
                            by providing valid identification documents within
                            one (1) week of receiving the notification by email
                            and/or call. The winner will have to submit all the
                            required documents within 30 days after ABC/its
                            agents contacts the winner through call or SMS or
                            email. It is the responsibility of the participant
                            to check the result link provided to your email
                            address post completion of Contest to know the
                            winners. And further it is the responsibility of
                            winners to claim their prize. The details required
                            for claiming the prize shall be mentioned along with
                            competition winner list. <br />
                            Failure to claim the prize and/or submit document
                            within the specified timeframe will result in
                            forfeiture of the prize(s) at our sole discretion.
                            <br />
                            The participant shall not have any right to transfer
                            the prize that has been awarded to him/her to any
                            other person. <br />
                            Delivery of prize will also be subject to the
                            winners giving the correct address and his/her
                            availability there. In the event, the prize cannot
                            be delivered due to reasons beyond the control of
                            ABC, the winner(s) shall collect them from the
                            nearest place, specified by ABC, at their own cost.
                            ABC will not be liable to reimburse the cost so
                            incurred by winner - whether travel, accommodation
                            or any other cost.
                            <br />
                            ABC shall have the right to forfeit any/all prize
                            for any reason including but not limited to
                            non-submission of necessary documents,
                            non-compliance with Applicable Law, non-submission
                            of documents pertaining to taxes.
                            <br />
                            Winner, who is found to have made any
                            misrepresentation or false claim for prize does
                            hereby agree to: <br />
                            Allow ABC to recover the prize; and
                            <br />
                            Completely indemnify ABC (including its agents,
                            employees, advisors), including any cost thereof.
                            <br />
                            All prizes will be given after deducting various
                            applicable taxes/ TDS under Income Tax Act, 1961,
                            license fees (if any) and charges as applicable.
                            <br />
                            Neither ABC nor its employees/agents are responsible
                            for guarantees or warranties of the quality of the
                            goods or services being offered as prizes nor is it
                            liable for any defect or deficiency of such goods or
                            services so awarded to the winners under this
                            Contest. For any issue with usage of the prize the
                            winners will contact the actual provider of the
                            goods/services only and shall not contact ABC in
                            this regard. <br />
                            All prizes offered in this Contest are subject to
                            availability. ABC reserves the right to provide
                            alternate prize.
                            <br />
                            The prizes shall be handed over by ABC/its agent
                            only on receipt of all the documents, from the
                            winners, required to process the distribution of the
                            prize, and satisfactory verification thereof by ABC.
                            <br />
                            All incidental costs, expenses, taxes or levies
                            related to the prize(s), if any, shall be
                            exclusively borne by the winner, including but not
                            limited to TDS (The winner shall submit TDS
                            certificate (or such similar document required under
                            Applicable Law) to ABC within the timeline specified
                            by ABC, if applicable), gift tax (if any),
                            Insurance, registration fees and any other taxes
                            applicable by the Central, State or Municipal
                            government. To avail and/or use the prize, winner
                            shall be solely liable for all applicable regulatory
                            and statutory compliances including but not limited
                            to any costs involved.
                            <br />
                            <br />
                            GENERAL CONDITIONS
                            <br />
                            <br />
                            This Contest is not being offered or is not
                            applicable to any territory where there is any legal
                            restriction applicable to contests of such nature or
                            the Contest. No prize under this Contest shall be
                            awarded to a person who is residing in any state/
                            territory where offering such Contest is legally
                            prohibited or requires ABC to obtain license/
                            approval/ registration.
                            <br />
                            Participation in the Contest constitutes full and
                            complete acceptance of these Terms & Conditions.
                            <br />
                            ABC reserves the right to change, modify, or
                            terminate the Contest at any time without prior
                            notice.
                            <br />
                            All decisions made by ABC regarding the Contest are
                            final and binding on you.
                            <br />
                            This Contest is for general engagement purposes only
                            and does not constitute investment, tax, or
                            financial advice.
                            <br />
                            Information or material published here does not
                            constitute an endorsement or solicitation to buy or
                            sell any securities or financial instruments.
                            <br />
                            You agree that this Contest is being made purely on
                            a “best endeavour” basis and subject to acceptance
                            of these Terms and Conditions. Your participation in
                            the Contest is voluntary and You expressly agree to
                            participate at your sole risk. ABC, its employees,
                            affiliates, agents shall not be responsible for any
                            loss, injury or any other liability to any
                            participant arising due to participation in the
                            Contest, access of the Site or you availing or using
                            any prize(s) offered to you.
                            <br />
                            The ABC do not make any commitment, express or
                            implied to respond to any feedback, suggestion
                            and/or queries of the participants.
                            <br />
                            You/participants agree and acknowledge that ABC in
                            no manner whatsoever (expressed or implied)
                            promotes/endorses/encourages any one to undertake
                            any activity in connection with the Contest that
                            might put at risk you/participant(s)/any third party
                            or is considered dangerous for the public. ABC shall
                            not be responsible/liable for any loss/damage/injury
                            suffered by you or caused by you to any third party
                            due to undertaking any such dangerous activity(ies).
                            <br />
                            Notwithstanding anything to the contrary, ABC
                            reserves the right to extend, cancel, discontinue,
                            suspend, disable or prematurely withdraw the Contest
                            at any time during its validity, at its sole
                            discretion, without assigning any reason, and
                            without any notice to you and the same shall be
                            binding on the participants.
                            <br />
                            ABC shall be entitled to use, the details of the
                            participant such as name, email ID, phone number,
                            photograph, the prizes awarded to them etc. for
                            their marketing purposes, if any. The participant
                            also hereby agree to the same. ABC may, at its
                            discretion, choose not to disclose the identity of
                            the participant to other participant unless
                            expressly mentioned herein. The participant also
                            consent to the information stored in the servers of
                            ABC/its agent or its service providers, inside or
                            outside India. Participants’ personal information
                            will be stored and processed in accordance with
                            Privacy Policy of ABC which is available at{" "}
                            <a href="https://www.adityabirlacapital.com/">
                                https://www.adityabirlacapital.com/
                            </a>
                            .<br />
                            You hereby agree, acknowledge and confirm that any
                            and all information pertaining to you, whether
                            provided by you on this Site or available publicly,
                            or otherwise, may be shared by ABC with its group
                            companies, affiliates and/or third parties for the
                            purposes of providing you the products offered/
                            extended by us, our group companies, affiliates
                            and/or third parties.
                            <br />
                            ABC and/ or any of its group companies or affiliates
                            shall send all communications to our users by text
                            messages, electronic mails, WhatsApp messages, push
                            notifications and/or via other progressive
                            technology in connection with your access to the
                            Site, participation in the Contest and/or as part of
                            our promotional and/or marketing strategy for
                            products offered/ extended by us or our group
                            companies or affiliates. ABC and/or its group
                            companies may use third-party service providers to
                            send alerts or to communicate with you. You
                            authorize ABC, its group companies, or its
                            affiliates and / or other entities providing
                            third-party services to ABC to override the DND
                            settings to reach out to you over calls, SMS,
                            emails, WhatsApp messages and any other mode of
                            communication. To the extent permissible under
                            Applicable Law, you agree that all documents,
                            notices, disclosures and other communications that
                            we provide to you electronically satisfy any legal
                            requirement that such communication(s) be in
                            writing.
                            <br />
                            You acknowledge, agree and confirm that you are
                            responsible for the contact details shared with ABC
                            and shall update ABC on any change to your contact
                            details.
                            <br />
                            If any Applicable Law prohibits you from taking part
                            in any competition of this nature, please refrain
                            from making any submissions to the Contest.
                            <br />
                            <br />
                            ADDITIONAL TERMS
                            <br />
                            <br />
                            You agree to grant ABC a non-exclusive, royalty-free
                            license to use, reproduce, modify, publish, and
                            distribute your video cheer in connection with this
                            Contest and its promotion.
                            <br />
                            You warrant that your video cheer is your original
                            creation and does not infringe on the intellectual
                            property rights of any third party.
                            <br />
                            You agree to indemnify and hold harmless ABC, its
                            affiliate from any and all claims arising from your
                            participation in the Contest.
                            <br />
                            By submitting a video entry, you grant ABC a license
                            to use it, and you agree to be solely responsible
                            for the content of your video. <br />
                            ABC reserves the right to disqualify any entry for
                            any reason whatsoever, including in the event it
                            deems such entry offensive, obscene, or
                            inappropriate and to remove any user-generated
                            content at its sole discretion. You agree to
                            indemnify and hold harmless ABC (including its
                            employees), affiliates, agents etc. from any and all
                            claims arising from your video content or your use
                            of the Site.
                            <br />
                            Upon participating in the Contest, the participant
                            confirms and waive the applicability of rules and
                            regulations of the National Do Not Call Registry to
                            the extent of receiving messages/calls in relation
                            to this Contest and shall imply their willingness to
                            receive all promotional messages including without
                            limitation daily updates on the score under the
                            current Contest and engagement calls / SMS,
                            promoting the new contest. <br />
                            <br />
                            INDEMNITY <br />
                            <br />
                            You shall indemnify, defend ABC, and hold ABC, its
                            affiliates, and their officers, associates,
                            successors, assigns, licensors, employees,
                            directors, agents, and representatives, harmless
                            from and against any direct or indirect losses,
                            actions, damages, penalties, cost and expenses,
                            claim, demand, lawsuits, judicial proceeding,
                            (including, without limitation, from all damages,
                            liabilities, settlements, costs and attorneys’ fees)
                            arising from or relating to: acts, commissions or
                            omissions, errors, misrepresentations, misconduct,
                            negligence, fraud, forgery, dishonesty, violation
                            attributable to you, any third party, your
                            participation in the Contest, contravention of
                            Applicable Law; or any claim from any Authority or
                            any third party as a result of any action or
                            inaction on your part, including but not limited to,
                            breach of these Terms and conditions. <br />
                            <br />
                            LIMITATION OF LIABILITY <br />
                            <br />
                            Notwithstanding anything to the contrary contained
                            herein, neither ABC nor any of its affiliates or
                            related parties (including their respective
                            employees, directors, advisors) shall have any
                            liability to you or any third party for any direct,
                            indirect, incidental, special, or consequential
                            damages or any loss of revenue or profits arising
                            under, directly, or indirectly, or relating, in any
                            manner whatsoever, including any action taken by any
                            Authority. <br />
                            Notwithstanding any provision to the contrary, ABC’s
                            (including its affiliates, related parties,
                            employees, agents etc.) maximum aggregate liability
                            to you under the Terms or otherwise for any loss in
                            connection with the taking part in the Contest shall
                            be limited at INR 1,000.
                            <br /> <br />
                            INTELLECTUAL PROPERTY RIGHTS
                            <br />
                            <br />
                            All rights, title, and interest in and to the Site
                            and the Contest, including text, graphics, software,
                            photographs and other images, videos, sounds,
                            trademarks, and service marks are owned by or
                            otherwise licensed to us. <br />
                            The use or misuse of any trademarks or any other
                            materials, except as permitted in these Terms, is
                            expressly prohibited and may be in violation of
                            copyright law, trademark law, and any other
                            Applicable Law.
                            <br />
                            The contents of the Site and the Contest are subject
                            to copyright protection. The contents of the Site
                            and contents related to the Contest may not be
                            copied, recopied, reproduced, or otherwise
                            redistributed. You should not copy, display,
                            download, distribute, modify, reproduce, republish,
                            or retransmit any information, text, or documents
                            contained on this Site/Contest or any portion
                            thereof in any electronic medium or in hard copy,
                            including any video entries uploaded thereon, or
                            create any derivative work based on such images,
                            texts or documents, without our express written
                            consent or as otherwise permitted in these Terms.
                            <br />
                            You may upload or submit certain media, information,
                            content, or other material, whether with or without
                            our request (collectively, “Content”). You hereby
                            grant us a non-exclusive, worldwide, perpetual,
                            irrevocable, transferable, sublicensable, and
                            royalty-free license to use, display, store, host,
                            communicate, make available, modify, adapt,
                            translate and create derivative works of the
                            Content. We take no responsibility and assume no
                            liability for Content submitted by you.
                            <br />
                            Except as stated in these Terms, nothing in these
                            Terms should be construed as conferring any right in
                            or license to our or any third party’s intellectual
                            property rights.
                            <br />
                            <br />
                            TERMINATION
                            <br />
                            <br />
                            Subject to the “Survival” clause of these
                            Terms/agreement/this arrangement/agreement/terms &
                            conditions shall stand terminated (unless
                            extended/continued in writing by ABC at its sole
                            discretion) on the occurrence of the following:
                            <br />
                            On expiry of a period of six months from July 14th,
                            2024;
                            <br />
                            In the event all prizes are forfeited in accordance
                            the terms hereof;
                            <br />
                            The contest is withdrawn/cancelled by ABC at its
                            sole discretion;
                            <br />
                            whichever is earlier.
                            <br />
                            MISCELLANEOUS PROVISIONS
                            <br />
                            <br />
                            Severability: If any provision of these
                            Terms/Agreement is determined by any court or other
                            competent Authority to be unlawful or unenforceable,
                            the other provisions of these Terms will continue in
                            effect. If any unlawful or unenforceable provision
                            would be lawful or enforceable if part of it were
                            deleted, that part will be deemed to be deleted, and
                            the rest of the provision will continue in effect
                            (unless that would contradict the clear intention of
                            the clause, in which case the entirety of the
                            relevant provision will be deemed to be deleted).
                            <br />
                            Regulatory Changes: You acknowledge and agree that
                            we may have to modify the Contest or the Site to
                            comply with the Applicable Laws. As a result of
                            this, you may be unable to access the Site or take
                            part in the Contest. We shall not be liable to you
                            for such inability to use the Site or take part in
                            the Contest pursuant to our compliance with the
                            Applicable Laws. <br />
                            Assignment: You shall not licence, sell, transfer,
                            or assign your rights, obligations, or covenants
                            under these Terms in any manner without ABC’s prior
                            written consent. ABC may grant (subject to any
                            conditions it deems appropriate) or withhold this
                            consent at its sole discretion. ABC may assign its
                            rights to any of its Affiliates, subsidiaries, or
                            parent company, or to any successor without any
                            prior notice to you.
                            <br />
                            Survival: The provisions related to Indemnity,
                            Limitation of Liability, Jurisdiction/Governing Law
                            and Miscellaneous Provisions and Intellectual
                            Property Rights and all other rights which
                            customarily survive in an agreement of this nature,
                            shall survive the termination of this agreement.
                            <br />
                            Dispute Resolution: This agreement/Terms and
                            conditions shall be governed by laws of India. Any
                            and all disputes arising out of or in connection
                            with this Contest and/or in connection with these
                            Terms and conditions shall be subject to the
                            exclusive jurisdiction of the courts in Mumbai.
                            <br />
                            <br />
                            <br />
                            <br />
                            Annexure 1- List of prizes
                            <br />
                            Grand prize winner will win a trip to Paris for two
                            people. The trip will include the following:
                            <br />
                            Air Tickets from Mumbai to Paris and Paris to
                            Mumbai;
                            <br />
                            Airport Transfers from the Paris airport to the
                            accommodation in Paris and from the accommodation to
                            the Paris airport;
                            <br />A 3-night stay at a 3-star accommodation in
                            Paris, with breakfast included; and
                            <br />A guided tour of the city of Paris.
                            <br />
                            ABC hereby reserves the right to modify or change
                            any component of the prize or the prize, described
                            hereinabove.
                            <br />
                            The total cost of the package will not exceed INR
                            1.15 lakh per person or 2.3 lakh for two people, in
                            any event.
                            <br />
                            The travel date will be determined by ABC/ its
                            agents at its sole discretion and may be between
                            September 15th, 2024- November 15th, 2024.
                            <br />
                            Other winners may be given Amazon voucher(s), and
                            other branded goodies like neck pillow(s), passport
                            cover(s), travel charger(s), etc.
                            <br />
                            The prize cannot be exchanged for cash or any
                            vouchers.
                            <br />
                            <br />
                            <br />
                        </div>
                        {/* <div
                            className="closeTNC centerX"
                            onClick={handleCloseTNC}
                        >
                            <b>Understood</b>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}
